.additional-stats {
  .main-content {
    .list {
      .ability {
        margin-top: 4px;
        line-height: 8px;

        .image {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }

        .name {
          padding: 6px 0px;
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          color: white;
        }
        .description {
          padding-left: 12px;
          margin-bottom: 12px;
        }
        .bullets {
          line-height: 20px;
          margin: 4px 14px 0px 0px;
        }
      }

      .bold-right {
        font-weight: 700;
        margin-right: 4px;
        color: white;
      }

      .bold-left {
        font-weight: 700;
        margin-left: 4px;
        color: white;
      }

      .entireChange {
        display: flex;
        align-items: center;
        padding: 0px;
      }

      .bullet {
        height: 5px;
        width: 5px;
        margin-right: 7px;
        border-radius: 50%;
        background-color: #bbbedb;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:58";