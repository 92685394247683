// $filter-color: #31334a;
// $filter-color: #25254b;
// $filter-color-selected: #383864;
$filter-color: #191937;
$filter-color-selected: #313160;
$filter-border-radius: 3px;

:root {
  --filter-color: #191937;
  --filter-not-default-font-color: #ffffff;
  // --filter-option-selected: #4B4E68;
  --filter-option-selected: #313160;
}

.filter-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  background-color: #0b0b23;
  font-size: 14px;

  .filter-modal_header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 10px;
    height: 50px;
    flex: 0 0 50px;
    border-bottom: 1px solid var(--border-color-grey);

    .filter-modal_close {
      position: absolute;
      top: 50%;
      right: 10px;
      padding: 8px;
      transform: translateY(-50%);

      svg {
        width: 16px;

        g,
        path {
          fill: #ffffff;
        }
      }
    }
  }

  .filter-modal_content {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .filter-modal_buttons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 90px;
    padding: 0 10px 29px;
    background-color: #0b0b23;
    box-shadow: 0;
    transition: box-shadow 0.3s;

    &.fade-content {
      box-shadow: 0px -25px 40px 10px #0b0b23;
    }

    .filter-modal_cancel {
    }
    .filter-modal_apply {
      margin-left: 12px;
    }
  }

  .filter-modal_options {
  }

  .filter-modal_radio-buttons {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 50px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color-grey);
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 35px;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .radio-button {
      @include flex-center;
      position: absolute;
      left: 0;
      top: 50%;
      width: 24px;
      height: 24px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      transform: translateY(-50%);

      &:after {
        display: block;
        opacity: 0;
        content: "";
        transform: scale(0);
        transition: opacity 0.2s, transform 0.2s;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    input:checked ~ .radio-button:after {
      width: 12px;
      height: 12px;
      opacity: 1;
      border-radius: 50%;
      background-color: #ffffff;
      transform: scale(1);
    }
  }
}

.role-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  svg,
  img {
    width: 16px;
  }
}

.duo-role-option {
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    width: 16px;
  }
}

.rank-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .rank-img {
    width: 20px;

    svg {
      width: 18px;

      g,
      path {
        fill: var(--font-color-main);
      }
    }
  }
}

.region-option {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .region-img-wrapper {
    flex: none;
    width: 20px;
    height: 20px;

    > div {
      width: 100%;
      height: 100%;
    }

    .region-img {
      width: 100%;
      height: 100%;

      &:not(.flag-img) {
        path,
        g {
          fill: var(--font-color-main);
          stroke: var(--font-color-main);
        }
      }
    }
  }
}

.filter-manager {
  position: sticky;
  z-index: 98;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // height: 36px;
  background-color: var(--background-main);
  box-sizing: content-box;

  .filter-width-wrapper {
    display: flex;
    align-items: center;
    margin-right: auto !important;
    -webkit-overflow-scrolling: touch;

    @include responsive("MOBILE_LARGE") {
      position: absolute;
      overflow-x: auto;
      left: -12px;
      right: -12px;
      margin-top: 12px;
      padding-bottom: 12px;
    }
    @include responsive("MOBILE_MEDIUM") {
      left: -8px;
      right: -8px;
      margin-top: 12px;
      padding-bottom: 12px;
    }
  }

  .filter-manager_label {
    display: flex;
    align-items: center;
    margin-right: 24px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;

    @include responsive("MOBILE_LARGE") {
      margin-right: 12px;
    }

    svg {
      padding: 2px;
      width: 24px;
    }
    span {
      margin-left: 6px;
    }
  }

  .filter-manager_container {
    position: relative;
    display: flex;
    // align-items: center;
    // flex: 1;
    // flex-wrap: wrap;
    // row-gap: 12px;

    // @include responsive("MOBILE_LARGE") {
    //   padding-left: 12px;
    //   padding-right: 12px;
    // }
    // @include responsive("MOBILE_MEDIUM") {
    //   padding-left: 8px;
    //   padding-right: 8px;
    // }

    > *:not(:last-child):not(.filter-manager_label) {
      margin-right: 12px;
    }

    .filter-select {
      @include responsive("DESKTOP_SMALL") {
        min-width: 0 !important;
        width: auto !important;
      }
    }

    .filter-manager_side-text {
      color: #cddcfe;
      font-size: 14px;
      font-weight: 500;

      strong {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .filter-tooltip-wrapper {
      position: relative;

      .clear-selected-option {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ffffff;
        transform: translateY(-50%);
        cursor: pointer;

        > img {
          width: 100%;
        }
      }
    }

    .more-filters {
      display: flex;
      align-items: center;

      > *:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
}

.filter-select {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  position: relative;
  height: 36px;
  border-radius: $filter-border-radius;
  background-color: $filter-color;
  cursor: pointer;

  &:hover {
    background-color: var(--filter-option-selected);
  }

  &.active {
    // background-color: var(--ugg-common-blue);
    background-color: var(--filter-option-selected);
    color: var(--filter-not-default-font-color);
  }

  .default-select__control {
    .default-select__value-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .default-select__indicators {
      .filter-manager & {
        display: none;
      }
    }
  }

  .custom-indicator {
    margin-left: 6px;
    width: 7px;

    path {
      fill: #ffffff;
    }

    &_search {
      width: 18px;
      height: 18px;
      opacity: 0.6;

      path {
        fill: #ffffff;
      }
    }
  }

  &.is-disabled {
    cursor: not-allowed;

    .Select-control {
      filter: grayscale(1);
    }
  }

  &.is-focused .Select-control,
  &.is-open .Select-control,
  .Select-control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: $filter-border-radius;
    // border: 1px solid $ugg-inline-blue;
    // border: 1px solid var(--border-color-grey);
    // background-color: $filter-color;
    background-color: var(--filter-color);

    .Select-multi-value-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .Select-input {
        background-color: transparent;
      }

      .Select-value {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 12px;
        // color: #FFFFFF;
        color: var(--font-color-main);
        font-size: 13px;
        font-weight: bold;

        > .Select-value-label {
          // color: #FFFFFF;
          color: var(--font-color-main);
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    .Select-arrow-zone {
      display: none;
    }
  }

  &.filter_not-default {
    .default-select__control {
      // border: 1px solid var(--ugg-common-blue) !important;
      // background-color: var(--ugg-common-blue) !important;
      background-color: var(--filter-option-selected) !important;

      .default-select__value-container {
        .default-select__single-value {
          color: var(--filter-not-default-font-color);

          .region-option {
            svg:not(.flag-img) {
              g,
              path {
                fill: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  &:not(.is-disabled) {
    &.is-focused .Select-control,
    &.is-open .Select-control,
    .Select-control {
      &:hover {
        // background-color: #4B4E68;
        background-color: var(--filter-option-selected);
        cursor: pointer;
      }
    }
  }

  .Select-menu-outer {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    border-radius: $filter-border-radius;
    border: none;
    background-color: transparent;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    z-index: 9999999;

    > .Select-menu {
      max-height: none;

      > .Select-option {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 12px;
        // background-color: $filter-color;
        background-color: var(--filter-color);
        // color: #FFFFFF;
        color: var(--font-color-main);
        font-size: 12px;

        &:not(:last-child) {
          // border-bottom: 1px solid $grey-border;
          border-bottom: 1px solid var(--border-color-grey);
        }

        &:hover {
          cursor: pointer;
        }

        &.is-selected,
        &.is-focused {
          // background-color: #4B4E68;
          background-color: var(--filter-option-selected);
          // color: #FFFFFF;
          color: var(--font-color-main);
          font-weight: bold;
        }

        &.role-option {
          display: flex;
          justify-content: space-between;
          align-content: center;

          img {
            width: 15px;
          }

          &:hover {
            // background-color: #4B4E68;
            background-color: var(--filter-option-selected);
            // color: #FFFFFF;
            color: var(--font-color-main);
            font-weight: bold;
          }
        }
      }
    }
  }
}

// Basic selector dropdown on mobile
.filter-select_mobile {
  display: flex;
  align-items: center;
  color: #cddcfe;
  font-size: 12px;
  font-weight: 500;

  .dropdown-indicator {
    margin-left: 4px;
    width: 7px;

    path {
      fill: #cddcfe;
    }
  }
}

.filter-select_champion {
  @include flex-center;
  position: relative;
  min-width: 72px;
  background-color: $filter-color;
  font-size: 14px;

  &:hover {
    background-color: $filter-color-selected;
    cursor: pointer;
  }

  .champion-control {
    @include flex-center;
    flex: 1;
    padding: 0 12px;
    height: 100%;
    white-space: nowrap;

    span.vs {
      margin-right: 6px;
    }
    .champion-label {
      input {
        width: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        color: #ffffff;

        &::placeholder {
          color: #bec3e1;
        }
      }
      .champion {
        display: flex;
        align-items: center;

        .champion-name {
          font-size: 14px;
          font-weight: 600;
        }

        .champion-image {
          position: relative;
          margin-right: 6px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: scale(1.1) translate(-45%, -45%);
          }
        }
      }
    }

    .clear-champion {
      @include flex-center;
      margin-left: 12px;
      padding: 4px;
      background-color: #77779f;
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 8px;
        fill: #ffffff;
      }
    }
  }

  .champion-menu {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    min-width: 100%;
    max-height: 500px;
    background-color: $filter-color;
    border-radius: $filter-border-radius;
    overflow: auto;
    z-index: 1;

    .champion-menu_item {
      display: flex;
      align-items: center;
      padding: 8px 12px;

      &.highlighted {
        background-color: $filter-color-selected;
      }

      img {
        margin-right: 12px;
        width: 26px;
        height: 26px;
      }

      .champion-name {
        // font-size: 13px;
      }
    }
  }
}

.multi-filter-champion {
  position: relative;

  .multi-filter-tag {
    background-color: #313160;
    padding: 2px 6px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-right: 6px;
    }

    .x-icon {
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      position: relative;
      margin-left: auto;
      cursor: pointer;
      margin-right: -6px;

      path {
        fill: #626696;
      }

      &:hover {
        path {
          fill: white;
        }
      }
    }
  }

  .multi-filter-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    background-color: #313160;
    border: none;
    border-radius: 3px;
    color: #cddcfe;
    cursor: pointer;

    &.no-champ {
      background-color: var(--filter-color);
      &.active {
        background-color: #25254b;
      }
      &.searching {
        background: #25254b;
      }
      ::placeholder {
        color: #cddcfe;
      }
    }

    &:hover {
      background-color: var(--filter-option-selected);
    }

    .multi-filter-label-tags {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      width: 100%;

      &.no-champ {
        display: flex;
        align-items: center;

        input {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #cddcfe;
          background: transparent;
          border: none;
          outline: none;
          min-width: 0;
          padding: 4px 0;
          flex-grow: 1;
          width: 225px;

          &.active {
            background-color: #25254b;
          }
        }
      }
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cddcfe;
      margin-left: -6px;
    }

    .champion-icon {
      margin: 0 5px;
      div {
        overflow: hidden;
        transform: scale(1.15);
        border-radius: 1px;
      }
    }

    .champion-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img {
      height: 16px;
      width: 16px;
      border-radius: 1px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }

    input {
      background: transparent;
      border: none;
      outline: none;
      color: inherit;
      font-size: inherit;
      min-width: 0;
      padding: 4px 0;
      flex-grow: 1;
      width: 225px;

      &::placeholder {
        color: inherit;
      }
    }

    svg {
      float: right;
    }

    .search-icon {
      width: 18px;
      height: 18px;
      opacity: 0.6;
      flex-shrink: 0;
      margin-left: 10px;

      path {
        fill: #626696;
      }
    }
  }

  .multi-filter-menu-summoner-page {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;
    max-height: 250px;
    background-color: #25254b;
    border-radius: $filter-border-radius;
    overflow: auto;
    z-index: 2;
    color: #cddcfe;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;

    .multi-filter-menu-tags {
      display: flex;
      align-items: center;
      padding: 8px 12px;
    }

    .multi-filter-menu-title {
      font-size: 10px;
      padding: 6px 12px;
      line-height: 13.5px;
    }

    .multi-filter-menu-item {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #cddcfe;

      &.highlighted {
        background-color: #383864;
      }

      img {
        margin-right: 12px;
        width: 26px;
        height: 26px;
      }

      span {
        color: #5f5f7b;
        font-weight: 400;
      }

      .champion-icon {
        overflow: hidden;
        margin-right: 8px;
        border-radius: 2px;

        div {
          transform: scale(1.15);
        }
      }
    }
  }
}

// role filter
.role-filter-container {
  display: grid;
  // grid-column-gap: 1px;
  // margin-right: 12px;
  height: 36px;
  // border: 1px solid $grey-border;
  background-color: $ugg-inline-blue;
  box-shadow: 0px 2px 3px -2px rgba(14, 37, 84, 0.22);

  .role-filter {
    grid-row: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100%);
    min-width: 36px;
    // border-right: 1px solid var(--border-color-grey);
    // border-top: 1px solid var(--border-color-grey);
    // border-bottom: 1px solid var(--border-color-grey);
    // background-color: $filter-color;
    background-color: var(--filter-color);

    &:first-child {
      border-top-left-radius: $filter-border-radius;
      border-bottom-left-radius: $filter-border-radius;
    }
    &:last-child {
      border-top-right-radius: $filter-border-radius;
      border-bottom-right-radius: $filter-border-radius;
    }

    &:hover {
      // background-color: #4B4E68;
      background-color: var(--filter-option-selected);
      cursor: pointer;
    }

    &.active {
      // background-color: $ugg-light-blue;
      // background-color: var(--ugg-common-blue);
      background-color: var(--filter-option-selected);
    }

    &.disabled {
      img {
        opacity: 0.1;
      }

      &:hover {
        background-color: $ugg-section-blue;
        cursor: not-allowed;
      }
    }

    svg,
    img {
      width: 16px;
      height: 16px;
    }
  }
}

// DUO TIER LIST
.role-pair-filters-container {
  display: flex;

  .role-pair-filter {
    position: relative;
    display: flex;
    align-items: center;
    width: 96px;
    height: 36px;
    border: 1px solid $grey-border;
    background-color: $ugg-section-blue;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &:hover {
      // background-color: $filter-color;
      background-color: var(--filter-color);
      // border-bottom: 1px solid $ugg-light-blue;
      border-bottom: 1px solid var(--ugg-common-blue);
      cursor: pointer;

      .separator {
        background-color: $ugg-section-blue;
      }
    }

    &.active {
      // background-color: $filter-color;
      background-color: var(--filter-color);
      // border-bottom: 1px solid $ugg-light-blue;
      border-bottom: 1px solid var(--ugg-common-blue);

      .separator {
        background-color: $ugg-section-blue;
      }
    }

    .role {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      svg,
      img {
        width: 16px;
        height: 16px;
      }
    }

    .separator {
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(20deg);
      width: 1px;
      background-color: $grey-border;
    }
  }
}

.select_modal {
  position: relative;

  .select_modal__toggle {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // &:hover {
    //   background-color: var(--filter-option-selected);
    // }
    //
    // &.active {
    //   // background-color: var(--ugg-common-blue);
    //   background-color: var(--filter-option-selected);
    //   color: var(--filter-not-default-font-color);
    // }
  }
}

.role-filter-container,
.select_modal__toggle,
.role-pair-filter {
  border-radius: $filter-border-radius;
}

.multi-builds-filter-button {
  min-width: 128px;
  height: 36px;
  background: #191937;
  border-radius: 3px;
  cursor: pointer;

  &:hover,
  &.active {
    background: #313160;

    .multi-builds-content {
      .image-holder {
        .rune-image-container,
        .item-image-container {
          background-color: #191937;
        }
      }
    }
  }

  span {
    position: relative;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
  }

  .multi-builds-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    justify-content: space-between;

    .image-holder {
      display: flex;
      gap: 6px;
      margin-top: 2px;

      .rune-image-container {
        height: 16px;
        width: 16px;
        border-radius: 1px;
        background-color: #11112a;
      }
      .item-image-container {
        height: 16px;
        width: 16px;
        border-radius: 1px;
        background-color: #11112a;
      }
      img {
        height: 16px;
        width: 16px;
        border-radius: 1px;
      }
    }
  }

  .dropdown-multis {
    position: absolute;
    background: #191937;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    width: 141px;
    margin-top: 12px;
    z-index: 2;
    overflow: hidden;

    .indv-multi-container {
      display: flex;
      height: 32px;
      justify-content: space-between;
      align-items: center;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        margin-left: 12px;
        color: #cddcfe;
      }

      .image-holder {
        display: flex;
        gap: 6px;
        margin-right: 12px;
        .rune-image-container {
          height: 20px;
          width: 20px;
          border-radius: 1px;
          background-color: #11112a;
        }
        .item-image-container {
          height: 20px;
          width: 20px;
          border-radius: 1px;
          background-color: #11112a;
        }
        img {
          height: 20px;
          width: 20px;
          border-radius: 1px;
        }
      }

      &:hover,
      &.active {
        background-color: #313160;

        .rune-image-container,
        .item-image-container {
          background-color: #191937;
        }
      }

      &.active {
        span {
          font-weight: 600;
          color: white;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:23";