.app-feature {
  display: grid;
  width: 240px;
  font-family: "Inter";

  .app-feature_preview {
    margin-bottom: 1px;
    width: 100%;
    height: 180px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    img {
      width: 100%;
    }
  }

  .app-feature_description-section {
    display: flex;
    flex-direction: column;
    padding: 18px;
    // min-height: 128px;
    background-color: #191937;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    gap: 12px;

    // &:hover {
    //   background-color: #25254B;
    // }
  }

  .header-container {
    display: flex;
    align-items: center;
  }

  .caption-title {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
  .caption-description {
    flex: 1;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #cddcfe;
  }

  .app-feature_footer {
    margin-top: 18px;

    .supported-platforms {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .platform-icon {
        flex: 0 0 12px;

        g,
        path {
          fill: #383864;
        }

        &.supported {
          g,
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:17";