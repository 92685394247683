.arena-match-summary {
  width: 100%;
  position: relative;
  .new-game-indicator {
    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid #11112a;
    border-radius: 50%;
    background-color: #ff9b00;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
  }

  .post-game-container {
    width: 100%; //This should be 644px, wait until postgame styling finished, 668 rn
    height: 100px;
    display: flex;
    cursor: pointer;

    @include responsive("TABLET") {
      justify-content: space-around;
      width: calc(100% - 30px);
    }

    .match-info {
      width: 99px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 12px 0px;
      .arena {
        font-family: Inter;
        font-size: 11px;
        font-weight: 700;
        line-height: 13px;
        letter-spacing: 0px;
        text-align: center;
        color: white;
      }
      .timestamp {
        font-family: Inter;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: center;
        color: #cddcfe;
        margin-top: 2px;
      }
      .placement {
        margin: 8px 0px;
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .result-time {
        display: flex;
        font-family: Inter;
        font-size: 11px;
        line-height: 13.31px;
        letter-spacing: 0px;
        text-align: center;
        gap: 4px;
        .result {
          font-weight: 700;
        }
        .time {
          font-weight: 500;
          color: #cddcfe;
        }
      }
    }
    .champion-info {
      width: 94px;
      height: 100%;
      margin-left: 6px;
      display: flex;
      align-items: center;
      .champion {
        position: relative;
        margin-right: 2px;

        .champion-face {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          border-radius: 3px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transform: scale(1.1);
          }
        }

        .champion-level {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 16px;
          border-radius: 3px;
          background-color: #191937;
          font-size: 10px;
          font-weight: 500;
          text-align: center;
        }
      }
      .augments {
        width: 46px;
        display: flex;
        flex-wrap: wrap;
        gap: 2px;
        .augment-holder {
          height: 22px;
          width: 22px;
          background-color: #223b80;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }

          &.loss {
            background-color: #53263e;
          }
        }
      }
    }
    .champion-stats {
      width: 81px;
      height: 100%;
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .KDA-totals {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // margin-top: 13px;
        .slash {
          color: #5f5f7b;
          // &.win {
          //   color: #223b80;
          // }
          // &.loss {
          //   color: #53263e;
          // }
        }
        .red {
          color: #ff4e50;
        }
      }
      .KDA-ratio {
        height: 13px;
        text-align: center;
        margin-top: 6px;
        font-size: 11px;
        font-weight: 700;
        span {
          color: #cddcfe;
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .cs,
      .vision-value {
        margin-top: 6px;
        height: 13px;
        color: #cddcfe;
        text-align: center;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .champion-items {
        width: 81px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        .items {
          display: flex;
          margin: auto;
          .item-wrapper {
            border-radius: 3px;
            background-color: #383864;
            //background-color: #22397C;
            overflow: hidden;
            .match_gold & {
              background-color: rgba(86, 58, 23, 1);
            }
            .match_win & {
              background-color: rgba(34, 59, 128, 1);
            }
            .match_lose & {
              background-color: rgba(83, 38, 62, 1);
            }

            .match_remake & {
              background-color: rgba(37, 37, 84, 1);
            }
          }
          .main-items {
            .items-container {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-template-rows: repeat(2, 1fr);
              grid-gap: 2px;
              justify-content: space-between;
            }
          }

          .trinket-icon {
            display: flex;
            //align-items: center;
            justify-content: center;
            margin-left: 2px;
          }
        }
      }
    }
    .all-players {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100px;
      width: 298px;
      margin-left: 18px;
      row-gap: 17px;

      @media screen and (max-width: 720px) {
        display: none;
      }

      .winning-teams .label,
      .losing-teams .label {
        text-transform: uppercase;
        text-align: left;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 6px;
      }

      .winning-teams .label {
        color: #3273fa;
      }

      .losing-teams .label {
        color: var(--red, #ff4655);
      }

      .team-grid-format,
      .duo-grid-format {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 134px;
        height: 64px;
        gap: 6px;
        justify-content: space-between;
      }

      .duo-grid-format {
        .team-list {
          width: 100%;

          .summoner-entry .summoner-name {
            width: 100%;
            max-width: 120px;
          }
        }
      }

      .team-list {
        // flex: 1;
        display: flex;
        flex-direction: column;
        width: 64px;
        // overflow: hidden;
        .summoner-entry {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 2px; //?
          }

          .champion-face {
            @include flex-center;
            margin-right: 4px;
            height: 12px;
            width: 12px;
            min-width: 12px;
            border-radius: 1px;
            overflow: hidden;
          }

          .summoner-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 48px;

            @include responsive("DESKTOP_SMALL") {
              max-width: 95px;
            }
            @include responsive("MOBILE_LARGE") {
              max-width: 60px;
            }

            a {
              font-size: 10px;
              font-weight: 500;
              color: #cddcfe;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
                color: lighten(#bbbedb, 20%);
              }
            }
          }

          &.chosen_win {
            .champion-face {
              border: 1px solid #ff9b00;
              border-radius: 8px;
            }

            .summoner-name > a {
              color: white;
              font-weight: 700;
            }
          }

          &.chosen_loss {
            .champion-face {
              border: 1px solid #ff9b00;
              border-radius: 8px;
            }

            .summoner-name > a {
              color: white;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .dropdown-button {
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 100px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    &:hover {
      background-color: #1e2b5e;
    }
    &.win {
      background-color: #22397c;
    }
    &.loss {
      background-color: #53263e;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-bottom: 7px;
    }
  }

  &.arena-match-summary_mobile {
    height: 83px;
    .post-game-container {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      padding: 6px 9px;
      .match-info {
        padding: 0;
        height: 13px;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .result {
          font-size: 11px;
          font-weight: 700;
        }
        .arena {
          margin-top: 0px;
        }
        .timestamp {
          margin-left: 6px;
          font-size: 11px;
          margin-top: 0px;
        }
      }
      .match-container {
        display: flex;
        margin-top: 4px;
        .champion-info {
          width: 104px;
          margin-left: 0px;
          .champion {
            margin-right: 4px;
          }
          .champion-face {
            height: 52px;
            width: 52px;
          }
          .champion-level {
            right: 0;
          }
          .augments {
            width: 48px;
            gap: 4px;
          }
        }
        .match-stats {
          width: 150px;
          display: flex;
          flex-direction: column;
          margin-left: auto;
          .champion-items {
            height: 18px;
            width: 150px;
            margin-left: 0px;
            margin-top: 8px;
            .items-container {
              display: flex;
              gap: 4px;
            }
          }
          .kda-stats {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
            .KDA-totals {
              color: #fff;
              font-family: Inter;
              font-size: 11px;
              font-weight: 700;
              margin-right: 16px;
              .slash {
                color: #5f5f7b;
                font-size: 11px;
                font-weight: 400;
              }
              .red {
                color: #ff4e50;
              }
            }
            .KDA-ratio {
              color: #fff;
              text-align: right;
              font-family: Inter;
              font-size: 11px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              .faded {
                color: #cddcfe;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

.arena-dropdown-container {
  width: 100%; //644px
  // height: 1133px;
  // border-top: 1px solid #11112a;
  margin-bottom: 20px;

  .stats-header {
    height: 48px;
    background-color: #191937;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .team-info {
      display: flex;
      align-items: center;
      margin: 0 0 12px 7px;
      gap: 4px;

      .team-logo {
        width: 12px;
        height: 12px;
      }

      .placement {
        color: #3273fa;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-right: 2px;

        &._4,
        &._5,
        &._6,
        &._7 {
          color: #ff4655;
        }
      }

      .team-name {
        color: #cddcfe;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .indv-stats {
      display: flex;
      gap: 32px;
      margin-bottom: 12px;
      @include responsive("MOBILE_LARGE") {
        gap: 22px;
      }

      .kda {
        width: 52px;
      }

      .damage {
        width: 56px;
      }

      .taken {
        width: 56px;
        @include responsive("MOBILE_LARGE") {
          display: none;
        }
      }

      .gold {
        width: 32px;
      }

      .items {
        width: 76px;
        margin-right: 6px;
      }

      span {
        color: #cddcfe;
        text-align: center;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 13px;
      }
    }
  }

  .player-row {
    height: 48px;
    width: 100%;
    background-color: #1d2754;
    display: flex;
    justify-content: flex-start;

    &.main {
      background-color: #223b80;
    }

    &.red {
      background-color: #311f39;

      &.main {
        background-color: #53263e;
      }
    }

    &._7 {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .summoner {
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #fff;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .champion {
        position: relative;
        margin-right: 2px;

        .champion-face {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 3px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transform: scale(1.1);
          }
        }

        .champion-level {
          position: absolute;
          bottom: -2px;
          left: -3px;
          width: 16px;
          border-radius: 3px;
          background-color: #191937;
          font-size: 10px;
          font-weight: 500;
          text-align: center;
        }
      }

      .summoner-spells {
        display: flex;
        flex-direction: column;
        margin-right: 2px;

        .summoner-spell {
          width: 22px;
          height: 22px;
          border-radius: 3px;
          background-color: #191937;

          &:first-child {
            margin-bottom: 2px;
          }

          > img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
        }
      }
      .augments {
        width: 36px;
        margin-left: 3px;
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .augment-holder {
          height: 16px;
          width: 16px;
          background-color: #223b80;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.main {
            background-color: #2a4796;
          }

          .augment-image {
            width: 100%;
            height: 100%;
          }

          &.match_lose {
            background-color: #53263e;

            &.main {
              background-color: #683550;
            }
          }
        }
      }

      .summoner-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 12px;
        max-width: 95px;

        @include responsive("MOBILE_LARGE") {
          max-width: 60px;
        }
        @include responsive("MOBILE_MEDIUM") {
          max-width: 50px;
        }
        @include responsive("MOBILE_SMALL") {
          max-width: 40px;
        }

        .summoner-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .summoner-rank {
          color: #cddcfe;
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    .kda-ratio {
      font-size: 11px;
      font-family: "Inter";
      font-weight: 700;

      .faded {
        color: #cddcfe;
        font-weight: 400;
        line-height: normal;
      }
    }

    .right-half {
      display: flex;
      margin-left: auto;
      gap: 32px;
      @include responsive("MOBILE_LARGE") {
        gap: 22px;
      }

      .kda {
        width: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #cddcfe;
        text-align: center;
        font-family: "Inter";
        font-size: 11px;
        font-weight: 500;

        .kda-totals {
          display: flex;
          justify-content: center;
          gap: 2px;
        }

        .slash {
          color: #5f5f7b;
          font-weight: 400;
        }
      }

      .damage {
        width: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 11px;

        .damage-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          border-radius: 2px;
          overflow: hidden;
          margin-top: 5px;

          .top-bar {
            background-color: #3273fa;
            height: 4px;
            border-radius: 2px;

            &.red {
              background-color: #ff4655;
            }
          }

          .grey-bar {
            background-color: #2a4796;
            height: 4px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            &.red {
              background-color: #53263e;
              &.main {
                background-color: #683550;
              }
            }
          }
        }
      }

      .taken {
        width: 56px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 11px;

        @include responsive("MOBILE_LARGE") {
          display: none;
        }

        .damage-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          border-radius: 2px;
          overflow: hidden;
          margin-top: 5px;

          .top-bar {
            background-color: #3273fa;
            height: 4px;
            border-radius: 2px;

            &.red {
              background-color: #ff4655;
            }
          }

          .grey-bar {
            background-color: #2a4796;
            height: 4px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            &.red {
              background-color: #53263e;
              &.main {
                background-color: #683550;
              }
            }
          }
        }
      }

      .gold {
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #cddcfe;
        text-align: center;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .items {
        display: flex;
        width: 76px;
        align-items: center;
        margin-right: 6px;

        .main-items {
          display: flex;
          position: relative;
          align-items: center;
          gap: 2px;
          left: 6px;

          .items-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-gap: 2px;
            justify-content: space-between;

            .item-wrapper {
              border-radius: 1px;
              overflow: hidden;

              &.match_win {
                background-color: #223b80 !important;
              }
              &.match_lose {
                background-color: #53263e !important;
              }
            }

            .trinket-icon {
              display: flex;
              justify-content: center;
              margin-left: 2px;
            }
          }
        }
      }
    }

    &.mobile-med-small {
      .left-half {
        display: flex;

        .champion-face {
          height: 34px;
          width: 34px;
        }

        .augments {
          gap: 2px;
        }

        .summoner-info {
          font-family: Inter;
          font-size: 11px;
          font-weight: 700;
          gap: 4px;
          margin-left: 6px;

          a:hover {
            text-decoration: underline;
          }
        }

        .kda {
          display: flex;
          width: 113px;
          justify-content: space-between;

          .kda-totals {
            color: #cddcfe;
            text-align: center;
            font-family: Inter;
            font-size: 11px;
            font-weight: 500;
            display: flex;
            gap: 2px;

            .slash {
              color: #5f5f7b;
              font-weight: 400;
            }
            .deaths {
              color: #ff4e50;
            }
          }
        }
      }
      .right-half {
        display: flex;
        flex-direction: column;
        margin-right: 6px;
        gap: 4px;
        .items-container {
          display: flex;
          gap: 4px;
          margin-top: 6px;
          .item-wrapper {
            background-color: #223b80;
            &.match_lose {
              background-color: #53263e;
            }
          }
        }
        .other-stats {
          display: flex;
          margin-left: auto;
          align-items: center;
          .damage-bar {
            position: relative;
            display: flex;
            align-items: center;
            width: 50px;
            flex: 0 0 50px;
            height: 12px;
            background-color: #11112a;
            margin-left: 4px;
            .damage-percent {
              height: 100%;
              background-color: #3273fa;
              &.match_lose {
                background-color: #ff4e50;
              }
            }
            .amount {
              position: absolute;
              font-weight: 600;
              right: 14px;
            }
          }
        }
      }
    }
  }
}

.arena-match-summary .dropdown-button {
  background-color: #1e2b5e;
  &.loss {
    background-color: #53263e;
  }
}

;@import "sass-embedded-legacy-load-done:51";