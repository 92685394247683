.landing-page-container_mobile {
  position: relative;
  padding-bottom: 24px;

  .all-landing-page-content {
    width: 100%;

    .landing-background {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -4;
      height: 100vh;

      > .overlay {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: radial-gradient(133.49% 100% at 50% 0%, rgba(7, 7, 32, 0.5) 0%, #070720 100%);
      }

      > video {
        position: absolute;
        z-index: -4;
        min-width: 1920px;
        width: 100%;
      }

      .background-image {
        background-image: url("https://static.bigbrain.gg/assets/lol/backgrounds/battle-bunny-aurora.png");
        background-size: cover;
        background-position: center;
        width: 100vw;
      }
    }

    .landing-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .ugg-logo {
        height: 50px;
        margin-top: 32px;
        cursor: pointer;
      }
    }
  }

  .game-switcher {
    display: flex;
    gap: 12px;
    padding-top: 36px;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;

    #lol {
      a {
        background-color: #191937;
        svg {
          // height: 20px;
          // width: 20px;
        }
        &:hover {
          background-color: #25254b;
        }
      }
    }

    #wow {
      &:hover {
        svg {
          path {
            fill: #ff9b00;
          }
        }
      }
    }
    #val {
      &:hover {
        svg {
          path {
            fill: #ff4655;
          }
        }
      }
    }

    .switch-icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .game-switch-icon {
      @include flex-center;
      width: 60px;
      height: 60px;
      background-color: #070720;

      &.active,
      svg {
        width: 32px;
        height: 32px;
      }

      border-radius: 3px;
    }

    .new-icon {
      @include flex-center;
      width: 32px;
      height: 16px;
      margin-top: 6px;
      background-color: #3273fa;
      font-weight: 700;
      font-size: 10px;
      color: #ffffff;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }

    .soon-icon {
      @include flex-center;
      height: 16px;
      margin-top: 6px;
      background-color: #25254b;
      font-weight: 700;
      font-size: 10px;
      color: #cddcfe;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }

    .beta-icon {
      @include flex-center;
      height: 16px;
      margin-top: 6px;
      background-color: #24a87b;
      font-weight: 700;
      font-size: 10px;
      color: #ffffff;
      border-radius: 2px;
      padding: 2px 4px;
      line-height: 12.1px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:54";