$rs-light: (
  name: "light-theme",
  variables: (
    --rs_modal_bg: #ffffff,
    --rs_modal_color: #5f5f7b,
    --rs_modal_selected-color: #383864,
    --rs_modal_selected-bg: #191937,
    --rs_scroll_track-bg: #ffffff,
    --rs_scroll_thumb-bg: #3273fa,
  ),
);

$rs-dark: (
  name: "dark-theme",
  variables: (
    --rs_modal_bg: #25254b,
    --rs_modal_color: #ffffff,
    --rs_modal_selected-color: #ffffff,
    --rs_modal_selected-bg: #191937,
    --rs_scroll_track-bg: #25254b,
    --rs_scroll_thumb-bg: #cddcfe,
  ),
);

$rs-themes: $rs-light, $rs-dark;

.region-selector-container {
  @mixin spread-map($map: ()) {
    @each $key, $value in $map {
      #{$key}: $value;
    }
  }

  @each $theme in $rs-themes {
    .#{map-get($theme, "name")} {
      @include spread-map(map-get($theme, "variables"));
    }
  }

  position: relative;

  .region-selector-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 200px;

    .region-selector-item {
      display: flex;
      align-items: center;
    }

    // &::-webkit-scrollbar {
    //   width: 6px;
    //   border-radius: 5px;
    //   margin-left: 10px;
    // }

    // &::-webkit-scrollbar-track {
    //   background: var(--rs_scroll_track-bg);
    //   border-radius: 5px;
    //   margin: 10px;
    //   // border-right: 20px solid white;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: var(--rs_scroll_thumb-bg);
    //   border-radius: 5px; /* Transparent border together with `background-clip: padding-box` does the trick */
    // }
  }
  .region-entry {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    &:hover {
      .region-text {
        font-weight: bold;
      }
    }

    .region-text {
      font-family: "Roboto";
      font-size: 14px;
      color: var(--rs_modal_color);
    }
  }

  .region-selector-modal {
    position: absolute;
    z-index: 10;
    left: 0;
    top: calc(100% + 6px);
    width: 230px;
    border-radius: 3px;
    background-color: var(--rs_modal_bg);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    color: var(--rs_modal_color);
  }

  .region-selector-button {
    position: relative;
    padding: 0 10px;
    border-radius: 3px 0px 0px 3px;
    background-color: var(--rs_modal_bg);
    cursor: pointer;

    .arrow-icon {
      display: flex;
      align-items: center;

      svg {
        flex: 0 0 9px;
        width: 9px;
        margin-left: 10px;

        g,
        path {
          fill: var(--rs_modal_color);
        }
      }
    }

    &:hover {
      background-color: rgba(49, 51, 74, 0.3);
    }
  }
}

;@import "sass-embedded-legacy-load-done:35";