.mobile-static {
  @include responsive("MOBILE_MEDIUM") {
    position: relative;
  }

  .mobile-slide {
    @include responsive("MOBILE_MEDIUM") {
      overflow-x: auto;
      line-height: 1.2;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        width: 20px;
        height: 512px;
        background: linear-gradient(270deg, #191937 0%, rgba(25, 25, 55, 0) 100%);
      }
    }

    .expanded-performance-container {
      padding: 18px 12px 8px 12px;
      @include responsive("MOBILE_MEDIUM") {
        min-width: 416px;
      }

      .description-header {
        display: grid;
        width: 100%;
        grid-template-columns: 34.5% 10% 11% 12% 14.5% 10% 8%;
        margin-bottom: 5px;

        & > div:first-of-type {
          margin-left: 30px;
          justify-content: flex-start;
        }

        .tab {
          display: flex;
          font-family: "Inter";
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #cddcfe;
          justify-content: center;

          .tab-title {
            &:hover {
              color: white;
              cursor: pointer;
              font-weight: 600;
            }
          }
          .selected {
            color: white;
            cursor: pointer;
            font-weight: 600;
          }
        }

        .tab-container-selected {
          padding-bottom: 6px;
          border-bottom: 2px solid #3273fa;
        }
      }

      .performance-rows {
        & .win {
          background-color: #1d2754;
        }
        & .loss {
          background-color: #311f39;
        }
        .performance-row {
          margin-bottom: 4px;
          align-items: center;
          display: grid;
          grid-template-columns: 5% 29.4% 10% 11% 12% 14.5% 10% 8%;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #cddcfe;

          .player-container {
            display: flex;
            flex-direction: column;
            margin-left: 6px;

            @include responsive("MOBILE_LARGE") {
              margin-left: 12px;
            }

            @include responsive("MOBILE_MEDIUM") {
              margin-left: 18px;
            }

            .username {
              margin-left: 6px;
              font-weight: 500;
              font-size: 12px;
              color: #ffffff;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }

            .progress-bar {
              width: 120px;
              height: 4px;
              border-radius: 2px;
              background-color: #11112a;
              margin-top: 6px;
              margin-left: 6px;

              @include responsive("MOBILE_MEDIUM") {
                width: 90px;
              }

              @include responsive("MOBILE_MEDIUM") {
                width: 80px;
              }

              .progress-win {
                height: 100%;
                background-color: #3273fa;
                border-radius: 2px;
              }

              .progress-loss {
                height: 100%;
                background-color: #ff4e50;
                border-radius: 2px;
              }
            }
          }

          .image-container {
            width: 28px;
            height: 28px;
            overflow: hidden;
            border-radius: 2px;
            margin-left: 10px;

            .champion-img {
              width: 28px;
              height: 28px;
              transform: scale(1.1);
            }
          }

          .kills,
          .kda,
          .damage,
          .gold,
          .wards,
          .cs {
            margin: auto;
            height: 42px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .selected-win {
            background-color: #1f2d61;
            font-weight: 700;
            color: white;
          }

          .selected-loss {
            background-color: #3b213a;
            font-weight: 700;
            color: white;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:49";