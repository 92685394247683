@import "variables";
@import "animations";

// @import "loader01";
@import "loader02";
// @import "loader03";
// @import "loader04";
// @import "loader05";
// @import "loader06";
// @import "loader07";
// @import "loader08";
// @import "loader09";
// @import "loader10";
// @import "loader11";
// @import "loader12";

.spinthatshit-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    @include loader02;
  }
}

;@import "sass-embedded-legacy-load-done:7";