$skill-row-height: 24px;

.skill-path-container {
  display: grid;
  row-gap: 6px;
  column-gap: 6px;
  grid-template-columns: minmax(100px, 200px) 1fr;
  padding-bottom: 4px;

  &_hide-skill-names {
    grid-template-columns: 24px 1fr;
  }

  .skill-order-row {
    display: contents;

    .skill-row-label {
      display: flex;
      align-items: center;
      flex: none;
      height: $skill-row-height;
      border-radius: 3px;
      background-color: #11112a;

      grid-column: 1 / 2;

      &__with-name {
        flex: 1;
      }

      .champion-skill-with-label {
        flex: none;
        width: 24px;
        height: 24px;

        .skill-img {
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
      }

      .skill-name {
        display: flex;
        align-items: center;
        padding: 0 12px;
        overflow: hidden;

        > span {
          color: #cddcfe;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .skill-order {
      flex: 1;
      display: flex;
      grid-column: 2 / 3;
      gap: 6px;
      color: #ffffff;

      .blank-row {
        width: 534px;
        border-radius: 3px;
        background-color: rgba(#25254b, 0.5);
      }

      .skill-up,
      .no-skill-up {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 3px;
        color: #ffffff;
      }

      .skill-up {
        font-weight: bold;
        background-color: #3273fa;
      }

      .no-skill-up {
        background-color: rgba(#25254b, 0.5);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:18";