.leaderboard_table {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #cddcfe;

  @media screen and (max-width: 750px) {
    padding: 12px;
  }

  .rt-tbody {
    .rt-tr-group {
      &.summoner-highlighted {
        border: 1px solid #3273fa;
        background-image: linear-gradient(to right, rgba(50, 115, 250, 0.2) 0%, rgba(50, 115, 250, 0.2) 100%),
          linear-gradient(to right, #11112a 0%, #11112a 100%);
      }
    }
  }

  .tier-lp_desktop {
  }
  .tier-lp_mobile {
    display: none !important;
  }
  .summoner-col {
    padding-right: 0 !important;
  }

  .summoner {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    flex: 1;
    max-width: 180px;
    overflow: hidden;

    &:hover {
      .summoner-name {
        text-decoration: underline;
      }
    }

    .profile-icon-container {
      margin-right: 12px;
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
      border-radius: 3px;
      background-color: #d8d8d8;
      overflow: hidden;

      > img {
        width: 100%;
      }
    }

    .summoner-name {
      font-weight: 700;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tier {
    @include flex-center;

    .rank-img {
      margin-right: 6px;
      width: 24px;
    }
  }

  .win-rate {
    @include flex-center;
    flex-direction: column;
    padding: 0 12px;
    flex: 1;

    .wr-text {
      display: flex;
      align-items: center;

      &_win-rate {
        font-weight: 700;
      }
      &_games {
        margin-left: 12px;
      }
    }

    .wr-bar {
      margin-top: 6px;
      max-width: 200px;
      width: 100%;
      height: 6px;
      border-radius: 3px;
      background-color: #25254b;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 750px) {
    .tier-lp_desktop {
      display: none !important;
    }
    .tier-lp_mobile {
      display: flex !important;
    }
  }

  @include responsive("MOBILE_MEDIUM") {
    .profile-icon-container,
    .rank-img,
    .wr-text_games,
    .wr-bar {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    .summoner {
      padding-left: 0px;
      padding-right: 0px;
    }

    .rank-img {
      display: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:64";