.ugg-tooltip {
  z-index: 999999999;
  position: absolute;
  // min-width: 100px;
  max-width: 400px;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  background-color: #0b0b23;
  border: 1px solid #4f4f60;
  border-radius: 3px;
  box-shadow: 0 2px 4px #070720;
  will-change: transform;

  &.tooltip-transition {
    &-enter-done {
      opacity: 1 !important;
      transform: rotate(360deg) translateZ(0px) scale(1) !important;
    }
  }

  font[size] {
    font-size: inherit;
  }

  > div {
    .arrow {
      z-index: 99;
      position: absolute;
      // left: 50%;
      width: 13px;
      height: 13px;
      border-left: 1px solid #4f4f60;
      border-bottom: 1px solid #4f4f60;
      background-color: #0b0b23;
    }
    .arrow-up {
      top: -7px;
      transform: translateX(-50%) rotate(135deg);
    }
    .arrow-down {
      bottom: -7px;
      transform: translateX(-50%) rotate(-45deg);
    }
    .arrow-left {
      left: -7px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    .arrow-right {
      right: -7px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

stats {
  // color: green;
}

unique {
  font-weight: 500;
}

.cost {
  color: #ff9b00;
}

.tooltip-cost-analysis,
.tooltip-champion-skill,
.tooltip-summoner-spell,
.tooltip-rune,
.tooltip-item {
  background-color: #0b0b23;
  // padding: 10px;
  word-break: break-word;

  .name {
    font-size: 12px;
    font-weight: 500;
    color: #3273fa;
  }

  .cooldown {
    color: $grey-text;
  }
}

.no-data-message {
  color: #ff9b00;
}

.tooltip-champion-counter {
  display: grid;
  grid-template-rows: 24px 25px;
  grid-template-columns: 24px 1fr 24px;
  grid-gap: 2px;

  .current-champion {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .win-comparison {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // width: 90px;
    height: 100%;
    padding: 0 2px;
    background-color: #0b0b23;
    color: #ebebf0;

    span {
      font-weight: bold;
    }

    span:nth-child(2) {
      color: $grey-text;
    }
  }
  .counter-champion {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }
  .total-matches {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background-color: #0b0b23;

    span:first-child {
      color: $grey-text;
    }
  }
}

.score-tooltip {
  width: 230px;
  height: 100%;
  background-color: #25254b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 0px 10px;

  .tooltip-text {
    font-size: 12px;
    font-weight: 500;
    color: #cddcfe;
    span {
      color: #fff07b;
    }

    &.green {
      span {
        color: #05bdcf;
      }
    }
  }

  .stats-section {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    color: #cddcfe;
    height: 45px;

    .stat-subsection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      font-size: 12px;
      width: 160px;

      .stat-display {
        color: white;
        font-size: 14px;
      }
    }
  }

  .role-img {
    @include flex-center;
    margin-bottom: 8px;
    width: 12px;
    height: 12px;

    svg {
      flex: 0 0 12px;
      width: 12px;
    }
  }

  .tooltip-diff-box {
    width: 36px;
    height: 15px;
    font-weight: 500;
    border-radius: 2px;
    display: flex;
    color: #ff4e50;
    background-color: rgba(255, 78, 80, 0.2);
    margin-left: 3px;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .down-arrow {
      path {
        fill: #ff4e50;
      }
    }

    .up-arrow {
      path {
        fill: #3273fa;
      }
    }

    &.blue {
      color: #3273fa;
      background-color: rgba(50, 115, 250, 0.2);
    }
  }

  .divider {
    height: 1px;
    width: 70px;
    background-color: #383864;
  }
}

;@import "sass-embedded-legacy-load-done:77";