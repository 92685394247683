.verification-container {
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #11112a;
  border-radius: 6px;
  padding: 48px;

  @media screen and (max-width: 510px) {
    padding: 24px;
  }

  .small-header {
    font-size: 20px;
    font-weight: 700;
  }

  .basic-text {
    font-size: 14px;
  }

  .summoner-name {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    .small-header {
      margin-bottom: 12px;
      font-size: 30px;
      font-weight: 500;
      font-family: "Barlow";

      @media screen and (max-width: 510px) {
        font-size: 24px;
      }
    }

    .step-text {
      margin-bottom: 24px;
      font-size: 16px;
      color: #cddcfe;

      @media screen and (max-width: 510px) {
        font-size: 14px;
      }
    }

    .basic-text {
      margin-bottom: 12px;
      color: #cddcfe;
      font-size: 14px;
    }
  }

  .code-copy-page {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 90%;
    margin-bottom: 30px;

    .code-form {
      display: flex;
    }

    .copy-label-container {
      display: flex;

      .copy-text {
        margin-right: 7px;
      }
    }

    .step-text {
      color: #cddcfe;
      font-size: 16px;
    }

    .basic-text {
      margin-top: 24px;
      font-weight: normal;
      color: #cddcfe;
      font-size: 14px;

      span {
        color: white;
        font-weight: 500;
      }
    }

    .code-form {
      margin-top: 12px;
    }

    img {
      margin-left: 20px;
    }

    .img1 {
      width: 220px;
      height: 80px;
    }

    .img2 {
      align-self: flex-start;
      width: 120px;
      height: 120px;
    }

    .img3 {
      margin-top: 14px;
    }

    .riot-code-verification-error {
      color: red;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:79";