#masthead {
  .masthead-ugg-search-bar {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 36px;
    min-width: 230px;
    max-width: 500px;
    border-radius: 3px;
    background-color: #0d0d28;

    @include responsive("MOBILE_LARGE") {
      max-width: 420px;
    }

    @include responsive("MOBILE_MEDIUM") {
      display: none;
    }

    ::placeholder {
      color: #8a8ebb;
      opacity: 1;
    }

    .react-autosuggest__container {
      position: static;
      width: 100%;
      height: 100%;

      &.react-autosuggest__container_placeholder {
        background-color: #0d0d28;
        border-radius: 3px;
      }

      .react-autosuggest__input {
        background-color: #0d0d28;
        width: 100%;
        height: 100%;
        color: #a9acd1;
        font-weight: 500;
        padding-left: 12px;
        padding-right: 34px;
        border-radius: 3px;
        font-size: 13px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      }

      .react-autosuggest__suggestions-container--open {
        color: black;
        width: auto;
        top: 44px;
        left: 0;
        right: 0;
        border: none;
        font-size: 14px;
        border-radius: 3px;
      }
    }

    .react-autosuggest__container--open {
      height: 100%;
    }

    .autosuggest-button {
      position: relative;
      fill: white;
      width: 40px;
      height: 100%;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;

      svg {
        width: 16px;
      }
    }

    .react-autosuggest__container--open ~ .autosuggest-button {
      border-bottom-right-radius: 0;
    }

    .spinthatshit-loader {
      position: absolute;
      right: 60px;
      width: 56px;
      transform: scale(0.4);
    }
  }
}

;@import "sass-embedded-legacy-load-done:28";