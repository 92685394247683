.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 19999999999;

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -100;
    background-color: rgba(#070720, 0.9);
    height: 100vh;
    width: 100vw;
  }

  .modal-window {
    position: relative;
    margin-top: $masthead-height + 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    z-index: 100000;

    @include responsive("MOBILE_LARGE") {
      margin-top: 77px;
      margin-left: 12px;
      margin-right: 12px;
      width: auto;
    }
  }
}

.header_feedback {
  padding: 24px 24px 0;

  .header_content_feedback {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-left: 14px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
}

.modal_settings {
  width: 472px;
  font-family: "Inter";
  background-color: #191937;

  .close-icon {
    margin: -5px;
    padding: 5px;
    width: 24px;

    g,
    path {
      fill: #7687ad;
    }

    &:hover {
      cursor: pointer;

      g,
      path {
        fill: #ffffff;
      }
    }
  }

  .header_feedback {
    padding: 24px 24px 0;

    .header_content_feedback {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding-left: 14px;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 20px;
        border-radius: 2px;
        background-color: #3273fa;
      }
    }
  }

  .nav {
    display: flex;
    padding: 0 24px;
    border-bottom: 2px solid #25254b;
  }

  .contact_body {
    padding: 24px;
    min-height: 300px;
    border-radius: 6px;
    background-color: #191937;
  }

  .contact-row {
    ~ .contact-row {
      margin-top: 18px;
    }

    label {
      font-size: 12px;
      line-height: 14px;
      color: #cddcfe;
      margin-bottom: 0px;
      display: block;
      width: 100%;

      > * {
        margin-top: 8px;
        border-radius: 3px;
      }

      input {
        display: block;
        padding: 12px;
        width: 100%;
        height: 36px;
        background-color: #25254b;
        box-shadow: 0px 2px 4px rgba(14, 37, 84, 0.22);
        outline: none;
        font-size: 12px;
        color: #cddcfe;
      }

      select {
        height: 36px;
        width: 100%;
        background-color: #25254b;
        box-shadow: 0px 2px 4px rgba(14, 37, 84, 0.22);
        border: none;
        font-size: 12px;
        color: #cddcfe;
        padding: 2px 12px 0 12px;

        option {
          height: 36px;
        }
      }

      textarea {
        width: 100%;
        background-color: #25254b;
        padding: 12px;
        outline: none;
        height: 144px;
        font-size: 12px;
        color: #cddcfe;
        resize: none;
        font-family: "Inter";
        border: none;
      }
    }

    .invalid {
      border: 1px solid #dc3545;
    }

    .invalid {
      &-control {
        border: 1px solid #dc3545 !important;
      }
    }

    &_button {
      display: flex;
      flex-direction: row-reverse;

      .button {
        span {
          color: #ffffff !important;
          font-weight: bold !important;
          font-size: 12px !important;
          text-align: center;
          line-height: 15px;
        }
        height: 36px;
        width: 150px;
        border-radius: 3px;
        background-color: #3273fa;
        color: #ffffff;

        @include responsive("MOBILE_LARGE") {
          width: 100%;
        }
      }
    }
  }

  .contact_form-group {
    display: flex;
    gap: 10px;

    @include responsive("MOBILE_LARGE") {
      flex-direction: column;
      gap: 18px;
    }
  }
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
  // -webkit-animation: fadeinout 2s linear forwards;
  // animation: fadeinout 2s linear forwards;
}

;@import "sass-embedded-legacy-load-done:30";