.match-block {
  position: relative;
  padding-bottom: 12px !important;
  background-color: #11112a;
  border-radius: 3px;

  .match-block_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    padding: 12px 18px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    @include responsive("MOBILE_LARGE") {
      padding: 12px;
    }

    .description {
      display: flex;
    }

    .match-block_filters {
      display: flex;
      align-items: center;

      @include responsive("MOBILE_LARGE") {
        > div > *:not(:first-child) {
          margin-left: 12px;
        }
      }

      > div > :last-child {
        margin-left: 12px;
      }

      .transparent-button {
        & > div {
          background-color: transparent;
        }
        & > div > div > div {
          font-weight: 500;
        }
      }

      .transparent-button-summoner {
        background-color: transparent;
        & > div > div > div {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .match-history_loading,
  .match-history_empty {
    margin: 0 auto;
    padding: 48px 0;
    min-height: 500px;
  }

  .match-history_empty {
    max-width: 388px;
    color: #cddcfe;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }

  .match-history_match-card {
    margin: 0px 18px;

    @include responsive("MOBILE_LARGE") {
      margin: 0 8px;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    @keyframes match-card-enter {
      0% {
        max-height: 0;
        overflow: hidden;
      }
      99% {
        max-height: 100px;
        overflow: hidden;
      }
      100% {
        max-height: auto;
        overflow: auto;
      }
    }

    &__exited,
    &__entering {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }

    &__entered {
      opacity: 1;
      // max-height: 100px;
      animation: 200ms match-card-enter;
      // transition: opacity .7s, max-height 2000ms;
      transition: opacity 0.7s;
    }
  }

  .load-more {
    padding: 12px 0;
  }

  .multi-search {
    position: relative;

    .multi-search-tag {
      display: flex;
      align-items: center;
      font-weight: 400;
      background-color: #070720;
      padding: 4px 6px;
      border-radius: 6px;
      flex-shrink: 0;

      &:not(:last-child) {
        margin-right: 6px;
      }

      .x-icon {
        width: 9px;
        height: 9px;
        opacity: 0.6;
        flex-shrink: 0;
        margin-left: 6px;
        cursor: pointer;

        path {
          fill: #ffffff;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .multi-search-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 12px;
      font-size: 14px;
      background-color: #191937;
      border: none;
      border-radius: 6px;
      color: #cddcfe;
      cursor: pointer;

      &:hover {
        background-color: #313160;
      }

      .multi-search-label-tags {
        display: flex;
        align-items: center;
      }

      input {
        background: transparent;
        border: none;
        outline: none;
        color: inherit;
        font-size: inherit;
        font-weight: 500;
        min-width: 0;
        padding: 4px 0;
        flex-grow: 1;
        width: 225px;

        &::placeholder {
          color: inherit;
          font-weight: 300;
        }
      }

      .search-icon {
        width: 18px;
        height: 18px;
        opacity: 0.6;
        flex-shrink: 0;
        margin-left: 10px;

        path {
          fill: #ffffff;
        }
      }
    }

    .multi-search-menu {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      min-width: 100%;
      max-height: 550px;
      background-color: $filter-color;
      border-radius: $filter-border-radius;
      overflow: auto;
      z-index: 1;
      color: #cddcfe;

      .multi-search-menu-tags {
        display: flex;
        align-items: center;
        padding: 8px 12px;
      }

      .multi-search-menu-title {
        font-size: 10px;
        padding: 8px 12px;
        line-height: 13.5px;

        &.divider {
          border-top: 1px solid #070720;
        }
      }

      .multi-search-menu-item {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        font-weight: 500;

        &.highlighted {
          background-color: $filter-color-selected;
        }

        img {
          margin-right: 12px;
          width: 26px;
          height: 26px;
        }

        span {
          color: #5f5f7b;
          font-weight: 400;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:38";