.summoner-profile_overview {
  display: grid;
  grid-template-columns: 324px 1fr;
  grid-gap: 12px;
  font-family: "Inter";

  @include responsive("DESKTOP_SMALL") {
    max-width: 100%;
  }
  @include responsive("TABLET") {
    width: 100%;
    grid-template-rows: min-content min-content;
    grid-template-columns: 100%;
    grid-gap: 12px;
    box-sizing: border-box;
  }
  @include responsive("MOBILE_LARGE") {
    grid-template-rows: min-content min-content;
    grid-template-columns: 100%;
    grid-gap: 12px;
    box-sizing: border-box;
  }

  .summoner-profile_overview__side {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(0, min-content));
    grid-gap: 12px;

    @include responsive("TABLET") {
      grid-template-columns: 334px 334px;
      grid-gap: 12px;
      justify-content: center;
    }
    @include responsive("MOBILE_LARGE") {
      grid-template-columns: calc(50% - 6px) calc(50% - 6px);
      grid-gap: 12px;
    }
    @include responsive("MOBILE_MEDIUM") {
      grid-auto-flow: row;
      grid-template-rows: repeat(auto-fit, minmax(0, min-content));
      grid-template-columns: 1fr;
      grid-gap: 12px;
    }
  }

  .summoner-profile_overview__main {
    @include responsive("TABLET", false) {
      max-width: 680px;
      margin: auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:37";