$masthead-search-bar-dark: (
  name: "dark-theme",
  variables: (
    --msb_bg: #0d0d28,
    --msb_input_color: #a9acd1,
    --msb_input_placeholder-color: #a9acd1,
    --msb_suggestions_bg: #0d0d28,
    --msb_suggestions_highlight-bg: #0b0b23,
    --msb_section-title_bg: #191937,
    --msb_section-title_text-color: #cddcfe,
    --msb_suggestion_text-color: #cddcfe,
    --msb_suggestion_text-color-secondary: #5c5c8e,
    --msb_suggestions_scroll_track-bg: #0d0d28,
    --msb_suggestions_scroll_thumb-bg: #cddcfe,
    --msb_search-icon-color: #ffffff,
  ),
);

$masthead-search-bar-light: (
  name: "light-theme",
  variables: (
    --msb_bg: #ffffff,
    --msb_input_color: #5f5f7b,
    --msb_input_placeholder-color: #5f5f7b,
    --msb_suggestions_bg: #ffffff,
    --msb_suggestions_highlight-bg: #ffffff,
    --msb_section-title_bg: #ffffff,
    --msb_section-title_text-color: #383864,
    --msb_suggestion_text-color: #5f5f7b,
    --msb_suggestion_text-color-secondary: #9d9db0,
    --msb_suggestions_scroll_track-bg: #ffffff,
    --msb_suggestions_scroll_thumb-bg: #3273fa,
    --msb_search-icon-color: #626696,
  ),
);

$masthead-search-bar-themes: $masthead-search-bar-light, $masthead-search-bar-dark;

.masthead-ugg-search-bar {
  @mixin spread-map($map: ()) {
    @each $key, $value in $map {
      #{$key}: $value;
    }
  }

  @each $theme in $masthead-search-bar-themes {
    &.#{map-get($theme, "name")} {
      @include spread-map(map-get($theme, "variables"));
    }
  }

  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  background-color: var(--msb_bg);
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));

  input:focus {
    outline: none;
  }

  .react-autosuggest__container {
    position: static;
    width: 100%;
    height: 100%;

    &.react-autosuggest__container_placeholder {
      background-color: transparent;
      border-radius: 3px;
    }

    .react-autosuggest__input {
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: 0 12px;
      border-radius: 3px;
      font-family: "Inter";
      font-size: 13px;
      font-weight: 500;
      color: var(--msb_input_color);

      &::placeholder {
        color: var(--msb_input_placeholder-color);
      }
    }

    .react-autosuggest__suggestions-container--open {
      position: absolute;
      top: calc(100% + 8px);
      left: 0px;
      right: 0;
      z-index: 10;
      max-height: 500px;
      width: auto;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: white;
      border-radius: 3px;
      background-color: var(--msb_suggestions_bg);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      // box-shadow: 0px 5px 30px 10px rgba(0, 0, 0, 0.5);
      overflow-y: auto;

      @include clean-scrollbar(
        16px,
        var(--msb_suggestions_scroll_track-bg),
        8px,
        var(--msb_suggestions_scroll_thumb-bg),
        8px,
        5px
      );

      .react-autosuggest__section-title {
        padding: 8px;
        font-size: 12px;
        background-color: var(--msb_section-title_bg);
        color: var(--msb_section-title_text-color);
      }

      .react-autosuggest__suggestion {
        padding: 4px 8px;
        cursor: pointer;

        &:first-child {
          padding-top: 8px;
        }
        &:last-child {
          padding-bottom: 8px;
        }

        .render-suggestion {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: row;

          .suggest-text {
            margin-left: 10px;
            font-size: 13px;
            color: var(--msb_suggestion_text-color);
          }

          .suggest-text-secondary {
            margin-left: 8px;
            color: var(--msb_suggestion_text-color-secondary);
            font-weight: 500;
            font-size: 10px;
          }

          .suggest-right {
            margin-left: auto;
          }

          img.page-type {
            position: absolute;
            width: 16px;
            height: 16px;
            top: -8px;
            left: -8px;
          }

          img.summoner-icon {
            width: 20px;
            height: 20px;
            border-radius: 2px;
          }
        }
      }

      .react-autosuggest__suggestion--highlighted {
        background-color: var(--msb_suggestions_highlight-bg);
      }
    }
  }

  .react-autosuggest__container--open {
    height: 100%;

    .react-autosuggest__input {
      border-bottom-left-radius: 0;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .autosuggest-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 100%;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 16px;
      fill: var(--msb_search-icon-color);
    }
  }

  .react-autosuggest__container--open ~ .autosuggest-button {
    border-bottom-right-radius: 0;
  }

  .spinthatshit-loader {
    width: 56px;
    transform: scale(0.4);
  }
}

;@import "sass-embedded-legacy-load-done:15";