@mixin at-themes($theme) {
  @at-root .search-bar-region-selector_theme__#{$theme}#{&} {
    @content;
  }
}

@mixin region-bg($region, $bg-color) {
  &.bg-#{$region} {
    background-color: $bg-color;

    // &.selected,
    // &:hover {
    //   background-color: $bg-color;
    //   color: white;
    // }
  }
}

.search-bar-region-selector {
  font-family: "Inter";

  &.no-labels-homepage {
    position: absolute;
    right: 60px;
    z-index: 1;

    .menu-button.region-tag {
      color: #fff;
      font-size: 12px;
      height: 24px;
      width: 36px;
    }

    .menu {
      background-color: white;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
      display: flex;
      left: -11px;
      overflow-x: hidden;
      top: 48px;
      width: 58px;
      scrollbar-width: none; //Firefox

      /* width */
      &::-webkit-scrollbar,
      &::-webkit-scrollbar:hover {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-track:hover {
        background: #5c5c8e4d;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb:hover {
        background: #5c5c8e;
      }

      .inner-menu {
        width: 100%;

        .options li {
          display: flex;
          height: 32px;
          justify-content: center;
          padding: 0;
          width: 58px;

          &:hover {
            background-color: #cddcfe;
          }

          .region-tag {
            color: white;
            height: 16px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .region-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cddcfe;
    background: #191937;
    width: 28px;
    height: 18px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 1px;

    @include at-themes("light") {
      color: #5f648a;
      background-color: #f4f7ff;
    }

    @include region-bg("na1", #ff9b00);
    @include region-bg("euw1", #1e48a8);
    @include region-bg("eun1", #3374a9);
    @include region-bg("kr", #4d2ac0);
    @include region-bg("br1", #6cd7a2);
    @include region-bg("jp1", #ec5d8c);
    @include region-bg("ru", #bf2828);
    @include region-bg("oc1", #8eced5);
    @include region-bg("tr1", #ec5c57);
    @include region-bg("la1", #f7c544);
    @include region-bg("la2", #ed642b);
    @include region-bg("ph2", #576bce);
    @include region-bg("sg2", #576bce);
    @include region-bg("th2", #af893d);
    @include region-bg("tw2", #00c280);
    @include region-bg("vn2", #145a66);
    @include region-bg("me1", #7d6641);
  }

  .menu-button:hover {
    cursor: pointer;
  }

  .menu {
    position: absolute;
    width: auto;
    top: 44px;
    left: 0;
    right: 0;
    border-radius: 3px;
    background-color: #0d0d28;
    overflow: auto;
    // box-shadow: 0px 1px 4px rgb(14 37 84 / 22%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @include at-themes("light") {
      background: white;
    }

    .menu_title {
      color: #cddcfe;
      background-color: #191937;
      padding: 8px;
      font-size: 12px;
      font-weight: 600;

      @include at-themes("light") {
        color: #383864;
        background: #f4f7ff;
      }
    }

    .options {
      li {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 28px;

        &:hover {
          background-color: #0b0b23;
          cursor: pointer;

          .region-label {
            font-weight: 600;
            color: #ffffff;
          }
        }

        .region-tag {
          margin-right: 8px;
        }

        .region-label {
          font-size: 14px;
          font-weight: 500;
          color: #cddcfe;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:16";