@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&family=Inter:wght@400;500;600;700&display=swap");

@import "./util/_loaders";

#__next {
  @apply min-h-screen h-screen;
}

@layer base {
  .progress-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: theme("colors.accent-blue.600");
  }

  @keyframes progress {
    0% {
      left: -100%;
    }
    10% {
      left: -97%;
    }
    20% {
      left: -92%;
    }
    30% {
      left: -82%;
    }
    30% {
      left: -62%;
    }
    40% {
      left: -38%;
    }
    50% {
      left: -18%;
    }
    60% {
      left: -14%;
    }
    80% {
      left: -7%;
    }
    90% {
      left: -3%;
    }
    100% {
      left: 0%;
    }
  }
  .progress::before {
    animation: progress 6s ease-in-out forwards;
  }
}

@layer components {
  .peek-map {
    .leaflet-control-zoom.leaflet-bar.center-control-bar {
      @apply w-full mr-0 p-0;
      height: 22px;
      margin-bottom: 6px;
      a {
        @apply w-5 h-5 flex justify-center items-center bg-purple-100 hover:bg-accent-purple-700;
        img {
          width: 11px;
          height: 9px;
        }
      }
    }

    .leaflet-control-zoom.leaflet-bar {
      @apply w-5;
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out {
        @apply items-start w-5 h-full justify-center flex bg-purple-100 font-inter;
        &:hover {
          @apply bg-accent-purple-700;
        }
        a,
        span {
          @apply text-xs h-[18px];
          line-height: 16px;
        }
      }
    }

    .leaflet-bottom {
      .leaflet-control {
        @apply mr-3 mb-3;
      }
    }

    .leaflet-control-layers {
      @apply bg-purple-200 text-lavender-200 border-0 outline-none;
    }
    .leaflet-bar {
      a,
      a.leaflet-disabled {
        @apply bg-purple-200 text-lavender-200 outline-none border-0;
      }
      a:hover,
      a:focus {
        @apply bg-purple-500 border-0 outline-none;
      }
      a[data-active="true"] {
        @apply dark:bg-accent-blue-400;
        img {
          @apply fill-white;
        }
      }
    }
  }
}

@layer utilities {
  .text-nowrap {
    text-wrap: nowrap;
  }

  .text-wrap {
    text-wrap: wrap;
  }
}

;@import "sass-embedded-legacy-load-done:0";