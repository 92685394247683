.summoner-profile_update-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 36px;
  border-radius: 3px;
  background-color: #3273fa;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  overflow: hidden;
  box-sizing: border-box;

  @include responsive("MOBILE_MEDIUM") {
    height: 30px;
  }

  &.success {
    max-width: 120px;
    background-color: var(--ugg-success-volxd);
    animation-name: buttonbounce;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }

  @keyframes buttonbounce {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.05);
    }
    20% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  &._is-updating,
  &.error {
    background-color: #3273fa;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    background-color: #184fc1;
    transition: width 0.5s;
  }

  .update-alert {
    @include flex-center;
    margin-left: 6px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    background-color: #1c3d8c;

    svg {
      flex: 0 0 3px;
      width: 3px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:36";