$breakpoints: (
  "MOBILE_SMALL": 0px 399px,
  "MOBILE_MEDIUM": 400px 599px,
  "MOBILE_LARGE": 600px 899px,
  "TABLET": 900px 1139px,
  "DESKTOP_SMALL": 1140px 1359px,
  "DESKTOP_MEDIUM": 1360px 1469px,
  "DESKTOP_LARGE": 1470px 9999px,
);

@include generate-media-queries($breakpoints);

// Used for pure css responsive
@mixin responsive($breakpoint, $cascade: true) {
  @if (map-get($breakpoints, $breakpoint) == null) {
    @error "Breakpoint '#{$breakpoint}' does not exist.";
  }

  @if ($cascade == false) {
    @media (min-width: nth(map-get($breakpoints, $breakpoint), 1)) and (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
      @content;
    }
  } @else {
    @media (max-width: nth(map-get($breakpoints, $breakpoint), 2)) {
      @content;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3";