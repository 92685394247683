@mixin side-nav-fade {
  opacity: 1;
  transition: opacity $side-nav-transition-duration;

  .side-nav_collapsed & {
    opacity: 0;
  }
}

@mixin side-nav-light-theme {
  .side-nav_theme__light & {
    @content;
  }
}

;@import "sass-embedded-legacy-load-done:69";