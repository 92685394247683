.scoring-container {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #cddcfe;
  padding: 0 12px;

  .summoner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 170px;
    padding: 5px 10px 5px 6px;
    border-radius: 3px;

    &.chosen {
      background-color: #383864;
      .match_gold & {
        background-color: #563a17;
      }
      .match_win & {
        background-color: #223b80;
      }
      .match_lose & {
        background-color: #53263e;
      }

      .match_remake & {
        background-color: #252554;
      }
    }

    .name-icon {
      width: 112px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .champion-face {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          transform: scale(1.1);
        }
      }

      .summoner-name {
        margin-left: 12px;
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &.highlight {
          color: #ff9b00;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .perf-score {
      &.max {
        color: white;
        font-weight: 700;
      }
    }
  }

  .mobile-team-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #11112a;
    border-radius: 3px;
    padding-top: 12px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #414165;
    margin: 18px 0px;
  }

  .team-container,
  .mobile-team-section {
    .team-title {
      display: flex;
      font-size: 12px;
      font-weight: 500;
      color: #cddcfe;
      margin-bottom: 5px;
      margin-top: 20px;

      .title-start {
        color: #3273fa;
        font-weight: 700;
        &.red {
          color: #ff4e50;
        }
      }
    }
  }

  .right-section {
    margin-left: 40px;
    margin-top: 18px;
    width: 100%;

    @include responsive("MOBILE_MEDIUM") {
      margin-left: 0px;
    }

    .playstyle-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 35px;
      color: white;
      font-size: 10px;
      font-weight: 700;
      margin: 10px 0px 20px 0px;

      .playstyle-label {
      }

      .playstyle-widget {
        display: flex;
        align-items: center;
        width: 75%;

        .playstyle-bar-outer {
          width: 100%;
          height: 4px;
          margin: 1px 6px 0px 6px;
          background-color: #05bdcf;
          .playstyle-bar-inner {
            height: 4px;
            background-color: #fff07b;
          }
        }
      }
    }

    .main-summoner-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;

      .scoring-header {
        display: grid;
        grid-template-columns: 13% 5% 75% 7%;
        align-items: center;
        background-color: #383864;
        .match_gold & {
          background-color: #563a17;
        }
        .match_win & {
          background-color: #223b80;
        }
        .match_lose & {
          background-color: #53263e;
        }

        .match_remake & {
          background-color: #252554;
        }
        height: 36px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        border-radius: 100px 30px 30px 100px;

        @include responsive("MOBILE_MEDIUM") {
          grid-template-columns: 13% 5% 67% 11%;
        }

        .role-icon-position {
          position: relative;
          left: -130%;
          top: 40%;
          @include responsive("DESKTOP_SMALL") {
            left: -175%;
          }

          @include responsive("MOBILE_LARGE") {
            left: -170%;
          }

          @include responsive("MOBILE_MEDIUM") {
            left: -100%;
          }
        }

        .icon {
          width: 36px;
          height: 36px;
          overflow: hidden;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .score-display {
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #191937;
          width: 24px;
          height: 24px;
          border-radius: 100%;
        }
      }
    }

    .main-summoner-section-mobile {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 12% 60% 20% 3%;
      width: 100%;
      height: 48px;
      border-radius: 0 0 3px 3px;
      background-color: #383864;
      font-size: 12px;
      font-weight: 500;
      color: #ff9b00;

      .icon {
        width: 32px;
        height: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .score-text {
        color: #cddcfe;

        span {
          font-weight: 700;
          color: white;
        }
      }
    }
  }
}

.subscores-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include responsive("MOBILE_SMALL") {
    margin-bottom: 10px;
  }

  &.top-bottom-margin {
    margin: 10px 0px;
  }

  .subscores-subsection {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    background-color: #11112a;
    border-radius: 6px;
    padding: 18px;

    .divider {
      height: 1px;
      background-color: #191937;
      margin: 18px 0;
    }

    .three-fourths-line {
      border: 1px dashed #414165;
      height: 110px;
      width: 1px;
      position: absolute;
      top: -12px;
      left: 75%;
      z-index: 10;
    }

    .subscores-title {
      color: #fff07b;
      display: grid;
      grid-template-columns: 7% 62% 24% 7%;
      align-items: center;

      .avg-box {
        height: 15px;
        width: 40px;
        background-color: #25254b;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #cddcfe;
        font-size: 10px;
      }
    }

    .score-circle {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: #51504c;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.blue {
      .subscores-title {
        color: #05bdcf;

        .score-circle {
          background-color: #13415e;
        }
      }

      .individual-subscore {
        &.colored {
          .outer-bar {
            .inner-bar {
              background-color: #05bdcf;
              opacity: 0.4;
            }
          }
        }

        &.highlighted {
          .outer-bar {
            .inner-bar {
              background-color: #05bdcf;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .individual-subscore {
    font-size: 12px;
    font-weight: 500;
    color: #cddcfe;
    display: grid;
    align-items: center;
    grid-template-columns: 30% 63% 7%;
    margin-bottom: 20px;

    .title-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .outer-bar {
      background-color: #191937;
      border-radius: 6px;
      height: 6px;
      margin-right: 10px;
      z-index: 100;

      .inner-bar {
        background-color: #383864;
        border-radius: 6px;
        height: 6px;

        &.bar-transition-appear-active {
          max-width: 0%;
        }
        &.bar-transition-appear-done {
          max-width: 100%;
          transition: max-width 700ms ease-in-out;
        }
      }
    }

    &.highlighted {
      .outer-bar {
        .inner-bar {
          background-color: #fff07b;
          opacity: 1;
        }
      }
    }

    &.colored {
      .outer-bar {
        .inner-bar {
          background-color: #fff07b;
          opacity: 0.4;
        }
      }
    }

    .score-text {
      font-size: 12px;
      font-weight: 700;
      justify-self: right;
      color: white;
    }
  }
}

;@import "sass-embedded-legacy-load-done:50";