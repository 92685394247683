.items-directory-home {
  display: flex;
  flex-direction: column;
  width: 100%;

  .directory-header {
    margin: 36px 0;

    h1 {
      font-family: "Barlow";
      font-weight: 600;
      font-size: 36px;
      line-height: 34px;
      margin-bottom: 30px;
    }

    h2 {
      // font-family: "Inter";
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #cddcfe;
    }
  }

  .filter-manager-items {
    margin-bottom: 24px;
  }

  .directory-items-container {
    display: flex;
    flex-direction: column;
    font-family: "Inter";

    .directory-items-section {
      padding: 18px;
      border-radius: 3px;
      background-color: #191937;
      margin-bottom: 12px;

      .items-container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }
    }
  }

  .items-search-input {
    display: flex;
    position: relative;
    align-items: center;

    input {
      width: 175px;
      height: 36px;
      border-radius: 6px;
      background-color: #25254b;
      border: 1px solid #25254b;
      padding-left: 14px;

      color: #cddcfe;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &::placeholder {
        color: #cddcfe;
      }

      &:focus {
        border: 1px solid #3273fa;
        outline: none;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      width: 16px;

      &:hover {
        cursor: pointer;
      }

      path {
        fill: #cddcfe;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:52";