$loader-animation-duration: 1s !default;

@mixin loader-rotate {
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin loader-scale {
  @keyframes loader-scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}

@function circle-position($size, $border-size, $coordinate) {
  $center: math.div($size, 2) - $border-size - math.div($border-size, 2);
  $half-size-border: math.div($size, 2) - $border-size + math.div($border-size, 2);
  // root value sqrt(2)/2 = 0.70710678118
  $root: 0.70710678118;
  @if $coordinate == y {
    @return round($center - ($half-size-border * $root));
  } @else {
    @return round($center + ($half-size-border * $root));
  }
}

@mixin loader03(
  // $color: $loader-color,
  $duration: $loader-animation-duration,
  $align: null
) {
  border-radius: 50%;
  animation: loader-rotate $duration linear infinite;

  @if ($align == center) {
    margin: 0 auto;
  }

  @if ($align == middle) {
    top: 50%;
    margin: -(math.div($size, 2)) auto 0;
  }

  @include loader-rotate;
}

.loader03 {
  @include loader03;
}

;@import "sass-embedded-legacy-load-done:1";