// colors
$ugg-light-blue: #08a6ff;
$ugg-red: #ff4e50;
$ugg-blue: #08a6ff;
$ugg-orange: #ff9c00;
$ugg-green: #30db9e;
$ugg-light-green: #229a71;
$ugg-sub-grey: #92929d;
$ugg-white: #ffffff;
$ugg-border-grey: #4e4f60;
$ugg-landing-blue: #17172e;
$ugg-section-blue: #222238;
$ugg-darkest-blue: #0b0b23;
$ugg-analytics-blue: #28283e;
$ugg-inline-blue: #2c2c40;
$input-bg: #31334a;

// tiers
$shinggo-tier: #ff4e50;
$meh-tier: #fcb1b2;
$okay-tier: #ffffff;
$gray-okay-tier: #cddcfe;
$good-tier: #7ea4f4;
$great-tier: #3273fa;
$volxd-tier: #ff9b00;

.shinggo-tier {
  color: $shinggo-tier;
}

.meh-tier {
  color: $meh-tier;
}

.okay-tier {
  color: $okay-tier;
}

.gray-okay-tier {
  color: $gray-okay-tier;
}

.good-tier {
  color: $good-tier;
}

.great-tier {
  color: $great-tier;
}

.volxd-tier {
  color: $volxd-tier;
}

// Colors
$grey-border: #2c2c40;
$grey-text: var(--font-color-grey);

.grey-text {
  color: $grey-text;
}

// constant sizes
$masthead-height: 65px;
$game-nav-height: 31px;

// User Verification Bar
$user-verification-bar-height: 40px;

:root {
  --masthead-height: #{$masthead-height};
  --game-nav-height: #{$game-nav-height};
}

;@import "sass-embedded-legacy-load-done:4";