$post-game-grid-1: 32% 8% 12% 13% 7% 7% 7% 14%; // desktop
$post-game-grid-2: 28% 8% 11% 10% 6% 8% 7% 22%; // small desktop
$post-game-grid-3: 35% 8% 16% 14% 10% 17%; // large mobile
$post-game-grid-1_without-perf: 40% 12% 13% 7% 7% 7% 14%; // desktop
$post-game-grid-2_without-perf: 36% 11% 10% 6% 8% 7% 22%; // small desktop
$post-game-grid-3_without-perf: 43% 16% 14% 10% 17%; // large mobile
$post-game-grid-header: 32% 7% 13% 13% 8% 7% 8% 14%;

.expanded-match_post-game {
  width: 100%;
  color: #ebebf0;
  font-size: 11px;
  font-weight: 700;

  .performance-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #414165;

    @include responsive("MOBILE_MEDIUM") {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content;

      .kill-icons {
        &_2 {
          justify-content: flex-end;
        }
      }

      .gold-distribution {
        grid-area: 2 / 1 / 3 / 3;
        align-self: flex-end;
        margin-top: 12px;
        margin-bottom: 2px;
      }
    }

    .champion-bans {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .ban-icon {
        width: 16px;
        height: 16px;

        &_blue path {
          fill: #3273fa;
        }
        &_red path {
          fill: #ff4e50;
        }
      }

      .champion-ban {
        position: relative;
        @include flex-center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;

        &:not(.champion-ban_reverse) {
          margin-left: 6px;
        }
        &.champion-ban_reverse {
          margin-right: 6px;
        }

        &_blue {
          border: 1px solid #3273fa;
        }
        &_red {
          border: 1px solid #ff4e50;
        }

        > *:not(.no-ban) {
          width: 100%;
          transform: scale(1.1);
        }

        .no-ban {
          width: 100%;
        }
      }
    }

    .kill-icons {
      display: flex;
      align-items: center;

      .icon-and-count {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 18px;
        }

        .icon {
          margin-right: 6px;
          width: 15px;

          &.tower {
            width: 13px;
          }
          &.blue path {
            fill: #3273fa;
          }
          &.red path {
            fill: #ff4e50;
          }
        }
        .count-text {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    .gold-distribution {
      font-size: 12px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 12px;
      width: 100%;

      .bar-distribution {
        display: flex;
        font-weight: normal;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        width: 100%;
        font-weight: 700;

        .bar {
          flex: 1;
          display: flex;
          max-width: 240px;
          margin: 0px 10px;
          border-radius: 4px;
          overflow: hidden;

          .team1px {
            height: 8px;
            background-color: #3273fa;
          }
          .team2px {
            height: 8px;
            background-color: #ff4e50;
          }
        }
      }
    }
  }

  .teams-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // &:first-child {
    //   margin-top: 50px;
    // }

    .expanded-match-card-team-combined {
      width: 100%;

      .row-header {
        display: grid;

        grid-template-columns: $post-game-grid-header;
        padding: 18px 12px 8px 12px;
        color: #cddcfe;
        font-size: 12px;
        font-weight: normal;
        text-align: center;

        @include responsive("DESKTOP_SMALL", true) {
          grid-template-columns: $post-game-grid-2;
        }
        @include responsive("TABLET", true) {
          grid-template-columns: $post-game-grid-2;
        }
        @media screen and (max-width: 700px) {
          grid-template-columns: $post-game-grid-3;
        }

        &.row-header_hide-perf {
          grid-template-columns: $post-game-grid-1_without-perf;

          @include responsive("DESKTOP_SMALL", true) {
            grid-template-columns: $post-game-grid-2_without-perf;
          }
          @include responsive("TABLET", true) {
            grid-template-columns: $post-game-grid-2_without-perf;
          }
          @media screen and (max-width: 700px) {
            grid-template-columns: $post-game-grid-3_without-perf;
          }
        }

        @include responsive("MOBILE_MEDIUM") {
          padding: 12px 12px 8px 12px;
          grid-template-columns: 1fr;
          > *:not(:first-child) {
            display: none !important;
          }
        }

        > *:first-child {
          text-align: left;
        }
        > *:last-child {
          display: flex;
          justify-content: center;

          .post-items-header {
            width: 100%;
            margin-right: 12px;

            @include responsive("DESKTOP_SMALL") {
              width: 164px;
            }
          }
        }
        .status-label {
          font-size: 14px;
          color: #3273fa;
          font-weight: 700;

          &.red {
            color: #ff4e50;
          }
        }

        .cs-header,
        .wards-header {
          @media screen and (max-width: 700px) {
            display: none;
          }
        }
      }

      .expanded-match-card-team {
        // Common
        .summoner-row {
          background-color: #1d2754;

          &.is-loss {
            background-color: #311f39;

            .damage-bar {
              .top-bar {
                background-color: #ff4e50 !important;
              }
            }
          }

          &.is-profile-owner_win {
            background-color: #223b80;
          }

          &.is-profile-owner_loss {
            background-color: #53263e;
          }

          .champion-container {
            position: relative;
            margin-right: 2px;
            margin-top: -2px;

            .champion-face {
              border-radius: 3px;
              overflow: hidden;

              & > div {
                transform: scale(1.1);
              }
            }

            .champion-level {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 16px;
              border-radius: 3px;
              background-color: #191937;
              font-size: 10px;
              font-weight: 500;
              text-align: center;
              transform: translate(-3px, 3px);
            }
          }

          .summoner-spell,
          .rune {
            @include flex-center;
            width: 16px;
            height: 16px;
            border-radius: 3px;
            background-color: #191937;
            overflow: hidden;

            img {
              flex: 0 0 16px;
              width: 16px;
              height: 16px;
            }
          }
        }

        .items-list {
          display: flex;
          align-items: center;
          justify-content: center;

          .items-container {
            .item-wrapper {
              //background-color: #383864;
              border-radius: 3px;
              background-color: #2a4796;
              &.is-loss {
                background-color: #682d49;
              }
              &.match_gold {
                background-color: rgba(149, 102, 30, 1);
              }
              overflow: hidden;
            }
          }

          .grid-items {
            display: flex;
            //align-items: center;
            justify-content: flex-end;

            .main-items {
              .items-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
              }
            }
          }

          .horizontal-items {
            .items-container {
              display: grid;
              grid-auto-flow: column;
              grid-template-columns: repeat(7, 1fr);
            }
          }
        }

        // "Desktop"
        .summoner-row_desktop {
          display: grid;
          grid-template-columns: $post-game-grid-1;
          text-align: center;
          justify-content: center;
          align-items: center;
          padding: 6px 0;
          font-weight: 500;
          color: #cddcfe;

          @include responsive("DESKTOP_SMALL", true) {
            grid-template-columns: $post-game-grid-2;
          }
          @include responsive("TABLET", true) {
            grid-template-columns: $post-game-grid-2;
          }
          @media screen and (max-width: 700px) {
            grid-template-columns: $post-game-grid-3;
          }

          &.summoner-row_hide-perf {
            grid-template-columns: $post-game-grid-1_without-perf;

            @include responsive("DESKTOP_SMALL", true) {
              grid-template-columns: $post-game-grid-2_without-perf;
            }
            @include responsive("TABLET", true) {
              grid-template-columns: $post-game-grid-2_without-perf;
            }
            @media screen and (max-width: 700px) {
              grid-template-columns: $post-game-grid-3_without-perf;
            }
          }

          > *:first-child {
            padding-left: 6px;
          }
          > *:last-child {
            padding-right: 6px;
          }

          .champion-face {
            flex: 0 0 36px;
            width: 36px;
            height: 36px;
          }

          .summoner-spells,
          .runes {
            margin-left: 4px;

            > *:first-child {
              margin-bottom: 4px;
            }
          }

          .player {
            display: flex;
            align-items: center;

            .summoner-name {
              font-size: 12px;
              font-weight: 700;
              color: #ebebf0;
              cursor: pointer;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 90px;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          .player-name-and-rank {
            margin-left: 12px;
            overflow: hidden;
            text-overflow: ellipsis;

            .summoner-name {
              font-size: 12px;
              font-weight: 700;
              color: #ebebf0;
              cursor: pointer;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 90px;

              &:hover {
                text-decoration: underline;
              }
            }

            .rank-section {
              display: flex;
              align-items: center;

              .rank-img {
                width: 14px;
                flex: 0 0 14px;
                filter: drop-shadow(0px 0px 1px rgba(#191937, 0.7));
              }

              .rank-text {
                margin-left: 4px;
                font-size: 11px;
                font-weight: 500;
                color: #cddcfe;
              }
            }
          }

          .damage {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .amount {
              margin-bottom: 3px;
              font-weight: 500;
              color: #ffffff;
            }

            .damage-bar {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 55px;
              border-radius: 2px;
              overflow: hidden;

              .top-bar {
                background-color: #3273fa;
                height: 4px;
                border-radius: 2px;
              }

              .grey-bar {
                background-color: rgba(#11112a, 0.6);
                height: 4px;
              }
            }
          }

          .kda {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 11px;

            .counts {
              .slash {
                color: #5f5f7b;
              }
            }
            .label {
              color: #cddcfe;
              font-weight: normal;
              .ratio {
                font-weight: 700;
              }
            }
          }

          .items-list {
            .grid-items {
              width: 76px;

              .main-items {
                .items-container {
                  grid-gap: 4px;
                  margin-right: 4px;
                }
              }
            }

            .horizontal-items {
              .items-container {
                grid-gap: 4px;
                width: 164px;
              }
            }
          }

          .cs,
          .wards {
            @media screen and (max-width: 700px) {
              display: none;
            }
          }

          .carry-score {
            @include flex-center;

            &.carry-score_highest {
              color: #05bdcf;

              .medal-icon {
                margin-left: 4px;
                width: 8px;
                flex: 0 0 8px;

                path {
                  fill: #05bdcf;
                }
              }
            }
          }
        }

        // Mobile
        .summoner-row_mobile {
          display: flex;
          padding: 6px;
          margin-bottom: 4px;
          border-radius: 3px;

          .col-1 {
            display: flex;
            align-items: center;
            flex: 1;

            .champion-face {
              flex: 0 0 34px;
              width: 34px;
              height: 34px;
            }

            .summoner-spells,
            .runes {
              margin-right: 2px;

              > *:first-child {
                margin-bottom: 2px;
              }
            }

            .player {
              margin-left: 2px;
              max-width: 70px;

              .player-info {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                height: 16px;

                .player-rank {
                  @include flex-center;
                  margin-right: 4px;
                  border-radius: 3px;
                  font-size: 11px;
                }

                .summoner-name {
                  max-width: 60px;
                  color: #ebebf0;
                  font-size: 11px;
                  font-weight: 700;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  height: 100%;
                }
              }

              .kda {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 16px;
                width: 115px;
                font-size: 11px;

                .counts {
                  white-space: nowrap;
                  .slash {
                    color: #5f5f7b;
                  }
                  .deaths {
                    color: $ugg-red;
                  }
                }
                .ratio {
                  margin-left: 6px;
                  color: #cddcfe;
                  font-weight: 700;
                }
                .kda-constant {
                }
              }
            }
          }

          .col-2 {
            .items-list {
              .horizontal-items {
                .items-container {
                  grid-gap: 2px;
                }
              }
            }

            .other-stats {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: 4px;
              font-size: 11px;

              .slash {
                color: #5f5f7b;
              }

              .cs {
                color: #cddcfe;
                margin-left: 8px;
              }

              .damage-bar {
                position: relative;
                display: flex;
                align-items: center;
                margin-left: 4px;
                width: 50px;
                flex: 0 0 50px;
                height: 12px;
                background-color: #11112a;

                .damage-percent {
                  height: 100%;
                  background-color: #3273fa;

                  &.is-loss {
                    background-color: #ff4e50;
                  }
                }

                .damage-text {
                  position: absolute;
                  font-weight: 600;
                  right: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:47";