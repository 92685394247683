// ads colors
$ugg-add-background: #191937; // dark blue
// $ugg-add-background: #25254b; // light blue

.ads-background {
  background-color: $ugg-add-background;
}

#eg-ccpa-msg-id {
  display: none;
}

;@import "sass-embedded-legacy-load-done:6";