html {
  position: relative;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 100%;
  width: 100%;
  float: left;
}

body {
  // font-family: "Helvetica Neue", "Roboto", Arial, Sans-Serif !important;
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.35;
  color: var(--font-color-main);
  text-align: left;
  background-color: var(--ugg-background-main);
  height: auto !important;
}

input,
form {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-weight: normal;
}

.grecaptcha-badge {
  display: none;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center,
%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Legos Footer
.footer {
  z-index: unset !important;
}

;@import "sass-embedded-legacy-load-done:5";