.footer {
  z-index: 100 !important;
  background-color: #070720;
  color: white;
  padding: 80px 0 120px 0;
  position: relative;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1016px;
    margin: 0 auto;
  }

  .footer-logo-section,
  .footer-links-section,
  .footer-terms-section,
  .footer-socials-section,
  .footer-brands-section {
    margin-bottom: 1.5rem;
  }

  .footer-logo-section {
    width: 22%;
  }

  .footer-logo {
    margin-bottom: 1.25rem;
  }

  .footer-copyright,
  .footer-disclaimer {
    font-family: "Inter", sans-serif;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #cddcfe;
    margin-bottom: 1rem;
  }

  .footer-disclaimer {
    color: #5c5c8e;
  }

  .footer-logo {
    width: 80px;
    height: 26px;
  }

  .footer-title {
    font-family: "Inter", sans-serif;
    font-size: 10px;
    color: #5c5c8e;
    margin-top: 15px;
  }

  .footer-links-list {
    margin-top: 20px;
    list-style: none;
    padding: 0;
  }

  .footer-link-item {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: #bec3e1;
    margin-bottom: 15px;
  }

  .footer-link {
    color: #bec3e1;
    text-decoration: none;
  }

  .footer-link:hover {
    text-decoration: underline;
  }

  .footer-link:visited {
    color: #bec3e1;
  }

  .footer-icon {
    margin-right: 0.5rem;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }

  .footer-link-item {
    display: flex;
    align-items: center;
  }

  .links-section-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .all-links {
    display: grid;
    grid-template-columns: 16% 17% 22% 21% 20%;
    width: 78%;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 780px) {
    .footer-title {
      margin-top: 0px;
    }

    .all-links {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto 0 0;
      width: 100%;
    }

    .footer-container {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
    }

    .footer-logo-section {
      width: 100%;
      margin-bottom: 25px;
    }

    .footer-links-section {
      margin-bottom: 25px;
    }

    .footer-disclaimer {
      margin-bottom: 0px;
    }

    .footer-copyright {
      margin-bottom: 15px;
    }

    .footer-logo {
      margin-bottom: 20px;
    }

    .footer-link-item {
      margin-bottom: 20px;
    }

    .links-section-container {
      width: 100%;
    }

    .footer-links-list {
      margin-top: 20px;
    }
  }
}
