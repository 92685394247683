.text-content-container {
  color: var(--font-color-main);
  max-width: 1200px;
  min-height: 1000px;
  margin: 36px auto 0;

  .text-content {
    font-size: 12px;
    color: var(--font-color-main);

    a {
      font-weight: bold;
      color: var(--ugg-common-blue);
      text-decoration: underline;
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 12px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 24px;
    }

    p {
      color: var(--font-color-main);
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }

    ul,
    ol {
      color: var(--font-color-main);
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      list-style: disc;
      padding-left: 30px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:57";