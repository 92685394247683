#main-content {
  color: var(--font-color-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

#content-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;

  @include responsive("TABLET") {
    padding-left: 0px;
  }
}

#content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  z-index: 100;
  width: 100%;
  min-height: calc(100vh - var(--masthead-height) - var(--game-nav-height));
}

.content-side-padding {
  padding-left: 24px;
  padding-right: 24px;

  @include responsive("TABLET") {
    padding-left: 12px;
    padding-right: 12px;
  }

  @include responsive("MOBILE_MEDIUM") {
    padding-left: 8px;
    padding-right: 8px;
  }
}

;@import "sass-embedded-legacy-load-done:27";