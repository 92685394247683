:root {
  --ugg-background-main: #070720;
  --ugg-background: #0b0b23;
  --ugg-background-alt: #17172e;
  --ugg-background-alt-2: #222238;
  --ugg-background-alt-3: #17172e;
  --font-color-main: #ffffff;
  --font-color-alt: #bbbedb;
  --font-color-grey: #92929d;
  --font-color-grey-alt: #bbbedb;
  --forward-slash: #4f4f60;
  --border-color-grey: #2c2c40;
  --ugg-box-shadow: none;

  --ugg-common-blue: #3273fa;
  --ugg-common-blue-hover: #1857db;

  --ugg-success-green: #2a9b73;
  --ugg-success-volxd: #ff9b00;

  --input-bg: #31334a;
  --input-border-color: #31334a;

  // Match Cards (Probuilds and Player Profiles)
  --match-card-bg-win: rgba(8, 166, 255, 0.3);
  --match-card-bg-win-hover: rgba(8, 166, 255, 0.4);
  --match-card-win-inner-divider: #064b80;
  --match-card-bg-loss: #45192b;
  --match-card-bg-loss-hover: #511b2e;
  --match-card-loss-inner-divider: #5a1f2e;
  --match-card-bg-remake: #222238;
  --match-card-bg-remake-hover: #28283e;
  --match-card-remake-inner-divider: #17172e;
}

;@import "sass-embedded-legacy-load-done:11";