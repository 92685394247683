.ReactTable {
  &.ugg-table-2 {
    font-size: 13px;
    font-weight: 500;
    border: none;
    color: var(--font-color-main);

    &.content-section {
      padding-top: 14px;

      @include responsive("MOBILE_MEDIUM") {
        padding: 8px;
      }
    }

    .rt-table {
      overflow: initial;
    }

    .rt-th,
    .rt-td {
      flex: 1;
      white-space: nowrap;
    }

    .rt-thead {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      background-color: #191937;
      font-size: 13px;

      &.-header {
        box-shadow: none;
        overflow: hidden !important;
      }

      .rt-tr {
        flex: 1;
        display: flex;
        width: 100%;
        overflow: hidden;
        text-align: center;

        .rt-th {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          border-right: none;
          color: #cddcfe;

          &.-sort-desc,
          &.-sort-asc {
            position: relative;
            box-shadow: none;
            color: #ffffff;
            font-weight: 700;
            box-shadow: none;

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 2px;
              border-radius: 1px;
              background-color: #3273fa;
            }
          }

          &.-sort-desc {
            &::before {
              bottom: 0;
            }
          }

          &.-sort-asc {
            &::before {
              top: 0;
            }
          }

          &.-cursor-pointer {
            cursor: pointer;
          }
        }
      }
    }

    .rt-tbody {
      scrollbar-width: thin;
      scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0);
      overflow-x: hidden;
      overflow-y: hidden;

      .rt-tr-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 54px;
        border: none;
        border-radius: 6px;

        @include responsive("MOBILE_MEDIUM") {
          height: 40px;
        }

        &:nth-child(2n + 1) {
          background-color: #11112a;
          // &:hover {
          //   background-color: #1C1C3B;
          // }
        }
        &:nth-child(2n) {
          // &:hover {
          //   background-color: #222244;
          // }
        }

        .rt-tr {
          flex: 1;
          display: flex;
          width: 100%;
          height: 100;

          .rt-td {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border: none;
            color: #cddcfe;

            &.rank {
              color: white;
            }

            &.sorted,
            &.highlight {
              background-color: rgba(#25254b, 1);

              &.is-in-odd-row {
                background-color: rgba(#25254b, 0.75);
              }
            }
          }
        }
      }
    }
  }

  .pagination-bottom {
    margin-top: 12px;

    .-pagination {
      border: none !important;
      box-shadow: none !important;
      padding: 0px;
    }

    .-btn {
      color: white;
      text-transform: uppercase !important;
      letter-spacing: 2px;
      font-size: 13px;
      font-family: "Helvetica Neue", "Roboto", Arial, Sans-Serif !important;
      font-weight: 800;
      height: 47px;
      width: 190px;
      background-color: #08a6ff;
    }

    .-next {
      display: flex;
      justify-content: flex-end;
    }

    .-pageInfo {
      font-size: 13px;
    }

    .-btn:hover {
      background-color: #20b6ff !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:12";