.desktop-app-landing-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 1px);
  padding-top: $masthead-height;
  overflow: hidden;

  .landing-background {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -4;
    height: 100vh;

    > .overlay {
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: radial-gradient(133.49% 100% at 50% 0%, rgba(7, 7, 32, 0.5) 0%, #070720 100%);
    }

    > video {
      position: absolute;
      z-index: -4;
      min-width: 1920px;
      width: 100%;
    }

    .background-image {
      background-image: url("https://static.bigbrain.gg/assets/lol/backgrounds/battle-bunny-aurora.png");
      background-size: cover;
      background-position: center;
      width: 100vw;
    }
  }

  .landing-page-layout-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 30px 10px 0px 10px;
    max-width: 1016px;
    width: 100%;
  }

  .search-container {
    height: 60px;
    max-width: 700px;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .desktop-app-landing-page_header {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;

    > img {
      height: 80px;
    }

    .header-right-side {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-weight: 700;
      gap: 12px;

      .download-button-container {
        align-items: center;
        display: flex;
        flex-direction: row nowrap;

        .download-btn {
          min-height: 36px;
          height: 36px;
          width: 150px;
        }

        .riot-logo {
          margin: 0 12px 0 18px;
          min-width: 24px;
          width: 24px;
        }

        p {
          color: #cddcfe;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }

  .featured-product-container {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;
    justify-content: space-between;
    margin-top: 180px;
    max-width: 1016px;
    width: 100%;

    @include responsive("TABLET") {
      padding: 0 12px;
    }

    .featured-product-left-side {
      display: flex;
      flex-direction: column;

      > p {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
      }

      .logo-and-title {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        font-size: 36px;
        font-weight: 700;
        gap: 12px;
        margin-bottom: 12px;

        .game-logo-container {
          align-items: center;
          background: #191937;
          border-radius: 3px;
          display: flex;
          height: 26px;
          justify-content: center;
          min-width: 26px;
          width: 26px;

          .game-logo {
            min-width: 18px;
            width: 18px;
          }
        }
      }

      .subtext {
        color: #cddcfe;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 36px;
      }

      .buttons-container {
        display: flex;
        flex-flow: row nowrap;
        gap: 12px;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 18px;

        .download-icon {
          min-width: 18px;
          width: 18px;
        }

        .promo-button {
          align-items: center;
          border-radius: 3px;
          display: flex;
          flex-flow: row nowrap;
          gap: 8px;
          height: 36px;
          justify-content: center;
          width: 150px;

          &.download-button {
            background-color: #ff4e50;

            &:hover {
              background-color: #ff3a3c;
            }
          }

          &.see-features-button {
            background-color: #3273fa;

            &:hover {
              background-color: #2963da;
            }
          }
        }
      }

      .riot-compliance-container {
        align-items: center;
        color: #5c5c8e;
        display: flex;
        flex-flow: row nowrap;
        font-size: 12px;
        font-weight: 400;
        gap: 12px;

        .riot-logo {
          min-width: 24px;
          width: 24px;

          path {
            fill: #5c5c8e;
          }
        }
      }
    }

    .featured-product-right-side {
      width: 602px;

      video {
        border-radius: 6px;
      }
    }
  }

  .game-library-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700;
    gap: 36px;
    margin-top: 80px;
    max-width: 1016px;
    width: 100%;

    @include responsive("TABLET") {
      padding: 0 12px;
    }

    .all-games {
      display: grid;
      font-size: 14px;
      gap: 18px;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 24px;

      @include responsive("TABLET") {
        grid-template-columns: repeat(4, 1fr);
      }

      .game-container {
        position: relative;

        .game-tag {
          border-radius: 1px;
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
          font-size: 10px;
          font-weight: 700;
          padding: 2px 4px;
          position: absolute;
          right: -6px;
          top: 6px;
          z-index: 1;
        }

        .img-container {
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 6px;
          flex-shrink: 0;
          height: 250px;
          position: relative;
          width: 189px;

          .hover-gloss {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            bottom: 0;
            display: none;
            height: 100%;
            left: 0;
            mix-blend-mode: soft-light;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;

            &:nth-last-child(1) {
              left: 1px;
              width: 99%;
            }
          }

          &:hover {
            .hover-gloss {
              display: block;
            }
          }
        }

        .game-title {
          margin-top: 8px;
        }
      }
    }
  }
}

.hd2-banner-container {
  align-items: center;
  background: linear-gradient(90deg, #0d0d28 14.08%, rgba(13, 13, 40, 0.55) 100%),
    url("https://static.bigbrain.gg/assets/hd2/hd2_banner_bg_rectangle_2.png");
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: top center;
  border: 1px solid #ffe900;
  border-radius: 3px;
  display: flex;
  flex-flow: row nowrap;
  font-family: "Inter", sans-serif;
  height: 114px;
  margin: 80px auto 0;
  max-width: 1062px;
  padding: 0 24px;
  position: relative;
  transition: background-size 0.5s ease;
  width: 100%;

  &:hover {
    background-size: 120%;

    @media screen and (max-width: 1000px) {
      background-size: 160%;
    }

    @media screen and (max-width: 750px) {
      background-size: 210%;
    }

    @media screen and (max-width: 560px) {
      background-size: 110%;
    }

    @media screen and (max-width: 395px) {
      background-size: 120%;
    }
  }

  @include responsive("TABLET") {
    width: calc(100vw - 48px);
  }

  @media screen and (max-width: 1000px) {
    background-size: 150%;
  }

  @media screen and (max-width: 750px) {
    background-size: 200%;
  }

  @media screen and (max-width: 560px) {
    background: linear-gradient(90deg, #0d0d28 14.08%, rgba(13, 13, 40, 0.55) 100%),
      url("https://static.bigbrain.gg/assets/hd2/hd2_banner_bg_mobile.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @media screen and (max-width: 516px) {
    flex-direction: column;
    gap: 11px;
    height: 146px;
    justify-content: center;
  }

  @media screen and (max-width: 395px) {
    background-size: 110%;
  }

  .new-tag {
    background: #3273fa;
    border-radius: 1px;
    font-size: 11px;
    font-weight: 800;
    left: 6px;
    padding: 2px 4px;
    position: absolute;
    top: 7px;
  }

  .uggxhd2-logo {
    min-width: 166px;
    width: 166px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    margin: 0 18px 0 24px;
    text-align: left;
    width: 100%;

    @media screen and (max-width: 516px) {
      align-items: center;
      text-align: center;
    }

    .title-container {
      font-family: "Orbitron", sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 3px;

      @media screen and (max-width: 1024px) {
        color: #ffe900;
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 0;
      }

      .hd2-title {
        color: #ffe900;
        font-weight: 900;
        margin-right: 6px;
      }

      &.without-span {
        display: none;

        @media screen and (max-width: 1024px) {
          display: block;
        }

        @media screen and (max-width: 516px) {
          display: none;
        }
      }

      &.with-span {
        display: block;

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media screen and (max-width: 516px) {
          display: block;
        }
      }
    }

    .subtext {
      color: #cddcfe;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &:not(.plus) {
        display: flex;

        @media screen and (max-width: 848px) {
          display: none;
        }
      }

      &.plus {
        display: none;

        @media screen and (max-width: 848px) {
          align-items: center;
          display: flex;
          flex-flow: row nowrap;
          gap: 9px;
        }

        .arrow-icon {
          min-width: 12px;
          width: 12px;

          @media screen and (max-width: 631px) {
            display: none;
          }

          @media screen and (max-width: 516px) {
            display: block;
          }

          path {
            fill: #cddcfe;
          }
        }
      }
    }
  }

  .hd2-button {
    align-items: center;
    background: #3273fa;
    border-radius: 3px;
    display: flex;
    flex-flow: row nowrap;
    font-size: 13px;
    font-weight: 700;
    gap: 6px;
    padding: 10px 12px;
    white-space: nowrap;

    @media screen and (max-width: 848px) {
      display: none;
    }

    &:hover {
      background: #1154df;
    }

    .skull-icon {
      min-width: 16px;
      width: 16px;
    }
  }
}

.deadlock-promo-container {
  display: flex;
  width: 100%;
  height: 48px;
  margin: 30px auto 0;
  margin-bottom: 56px;
  background: url("https://static.bigbrain.gg/assets/lol/backgrounds/deadlock-banner-bg.jpg") #090b0a 124.48px -36px / 100.424% 395.833%
    no-repeat;
  background-size: 316px;
  background-position: right 0px top -32px;

  @media screen and (min-width: 900px) {
    width: 678px;
  }

  .content-container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    .logo-and-text-container {
      display: flex;
      margin-left: 12px;
      .deadlock-logo {
        height: 36px;
        width: 36px;
        margin-right: 22px;
      }
      .middle-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .top-text {
          color: #efdec0;
          font-family: "Barlow Condensed";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .bottom-text {
          color: #e1e1df;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: none;
          @media screen and (min-width: 605px) {
            display: block;
          }
        }
      }
    }
    .cta-button {
      height: 30px;
      width: 30px;
      background: #7f513a;
      display: flex;
      margin-right: 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 3px;
      color: #fff;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media screen and (min-width: 605px) {
        height: 30px;
        width: 176px;
        padding: 10px 12px;
      }
      span {
        display: none;
        @media screen and (min-width: 605px) {
          display: block;
        }
      }
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background-color: #62331b;
      }
    }
  }
}

.tockers-promo-container {
  display: flex;
  width: 340px;
  height: 84px;
  border-radius: 3px;
  background: linear-gradient(90deg, rgba(196, 235, 236, 0) 14.41%, #14133e 43.3%),
    url("https://s3.us-east-2.amazonaws.com/static.bigbrain.gg/assets/lol/backgrounds/wow-banner-bg.png") #14133e 204.505%
      245.323% no-repeat;
  background-position: 0;
  align-items: center;
  background-size: 222px 84px;
  justify-content: space-between;
  margin: 30px auto 0;
  max-width: 675px;
  @media screen and (min-width: 483px) {
    height: 48px;
    width: 435px;
    background: linear-gradient(90deg, rgba(20, 19, 62, 0.15) 0%, #14133e 100%),
      url("https://s3.us-east-2.amazonaws.com/static.bigbrain.gg/assets/lol/backgrounds/wow-banner-bg.png") #14133e no-repeat;
    background-position: -14px 0;
    background-size: 222px;
  }
  @media screen and (min-width: 534px) {
    width: 486px;
    background: linear-gradient(90deg, rgba(20, 19, 62, 0.15) 0%, #14133e 100%),
      url("https://s3.us-east-2.amazonaws.com/static.bigbrain.gg/assets/lol/backgrounds/wow-banner-bg.png") #14133e no-repeat;
    background-size: 222px;
  }
  @media screen and (min-width: 600px) {
    width: 507px;
    background: linear-gradient(90deg, rgba(20, 19, 62, 0.15) 0%, #14133e 100%),
      url("https://s3.us-east-2.amazonaws.com/static.bigbrain.gg/assets/lol/backgrounds/wow-banner-bg.png") #14133e no-repeat;
    background-size: 222px;
  }
  @media screen and (min-width: 657px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 900px) {
    margin-top: 0;
    margin-bottom: 60px;
  }
  .promo-content {
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    row-gap: 9px;
    @media screen and (min-width: 483px) {
      margin-left: 9px;
      flex-direction: row;
      align-items: center;
    }
    @media screen and (min-width: 534px) {
      margin-left: 9px;
      flex-direction: row;
      align-items: center;
    }
    @media screen and (min-width: 657px) {
      width: 100%;
      position: relative;
    }

    .tockers-logo {
      display: none;
      @media screen and (min-width: 483px) {
        height: 30px;
        width: 30px;
        display: block;
      }
    }
    .first-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0px;
      @media screen and (min-width: 483px) {
        margin-left: 52px;
        row-gap: 0;
      }
      @media screen and (min-width: 534px) {
        margin-left: 91px;
      }
      @media screen and (min-width: 600px) {
        margin-left: 90px;
      }
      @media screen and (min-width: 657px) {
        margin-left: 101px;
      }

      .first-row {
        display: flex;
        flex-direction: row;
        row-gap: 2px;
        align-items: center;
        @media screen and (min-width: 483px) {
          row-gap: 0;
        }
        .new-tag {
          height: 16px;
          width: 32px;
          background: #3273fa;
          border-radius: 1px;
          color: white;
          font-family: "Inter", sans-serif;
          font-size: 10px;
          font-weight: 700;
          padding: 2px 4px;
          margin-right: 3px;
        }
        .top-text {
          color: white;
          font-family: "Barlow Condensed";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }
      .bottom-text {
        color: white;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: none;

        @media screen and (min-width: 700px) {
          display: block;
        }
      }
    }
    .cta-button {
      background-color: #3273fa;
      border-radius: 3px;
      align-items: center;
      display: flex;
      width: 140px;
      height: 24px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      &:hover {
        background-color: #1154df;
      }
      @media screen and (min-width: 483px) {
        height: 30px;
        width: 30px;
        margin-left: 16px;
      }
      @media screen and (min-width: 534px) {
        margin-left: 28px;
      }
      @media screen and (min-width: 600px) {
        margin-left: 38px;
      }
      @media screen and (min-width: 657px) {
        width: 112px;
        height: 30px;
        margin-right: 9px;
        margin-left: 20px;
        position: absolute;
        right: 9px;
      }
      span {
        color: #fff;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 483px) {
          display: none;
        }
        @media screen and (min-width: 657px) {
          display: block;
        }
      }
      .arrow-icon {
        min-width: 16px;
        width: 16px;

        path {
          fill: white;
        }
      }
    }
  }
}

.promo-container {
  background-color: #14133e;
  display: flex;
  width: 340px;
  height: 84px;
  border-radius: 3px;
  background-position: 0;
  align-items: center;
  background-size: 222px 84px;
  justify-content: center;
  margin: 30px auto 0;
  max-width: 675px;
  position: relative;
  @media screen and (max-width: 481px) {
    justify-content: space-between;
    &::before {
      display: none !important;
    }
  }
  @media screen and (max-width: 658px) {
    &::before {
      width: 48px !important;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 149px;
    height: 48px;
    background-image: url("https://static.bigbrain.gg/assets/marvel-rivals/backgrounds/marvel-rivals-banner-bg.png");
    display: block;
    background-repeat: no-repeat;
  }
  @media screen and (min-width: 483px) {
    height: 48px;
    width: 435px;
    background-position: -14px 0;
    background-size: 222px;
  }
  @media screen and (min-width: 534px) {
    width: 486px;
    background-size: 222px;
  }
  @media screen and (min-width: 600px) {
    width: 507px;
    background-size: 222px;
  }
  @media screen and (min-width: 657px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 900px) {
    margin-top: 0;
    margin-bottom: 60px;
  }
  .promo-content {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    row-gap: 9px;
    margin: auto;
    align-items: center;
    @media screen and (min-width: 483px) {
      margin-left: 9px;
      flex-direction: row;
    }
    @media screen and (min-width: 534px) {
      margin-left: 9px;
      flex-direction: row;
      align-items: center;
    }
    @media screen and (min-width: 657px) {
      width: 100%;
      position: relative;
    }
    .first-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0px;
      @media screen and (min-width: 483px) {
        margin-left: 52px;
        row-gap: 0;
      }
      @media screen and (min-width: 534px) {
        margin-left: 91px;
      }
      @media screen and (min-width: 600px) {
        margin-left: 90px;
      }
      @media screen and (min-width: 657px) {
        margin-left: 158px;
      }
      .first-row {
        display: flex;
        flex-direction: row;
        row-gap: 2px;
        align-items: center;
        @media screen and (min-width: 483px) {
          row-gap: 0;
        }
        .new-tag {
          height: 16px;
          width: 32px;
          background: #3273fa;
          border-radius: 1px;
          color: white;
          font-family: "Inter", sans-serif;
          font-size: 10px;
          font-weight: 700;
          padding: 2px 4px;
          margin-right: 3px;
        }
        .top-text {
          color: white;
          font-family: "Barlow Condensed";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          text-align: left;
        }
      }
      .bottom-text {
        color: white;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: none;

        @media screen and (min-width: 700px) {
          display: block;
        }
      }
    }
    .cta-button {
      background-color: #3273fa;
      border-radius: 3px;
      align-items: center;
      display: flex;
      width: 140px;
      height: 24px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      &:hover {
        background-color: #1154df;
      }
      @media screen and (min-width: 483px) {
        height: 30px;
        width: 30px;
        margin-left: 16px;
      }
      @media screen and (min-width: 534px) {
        margin-left: 28px;
      }
      @media screen and (min-width: 600px) {
        margin-left: 38px;
      }
      @media screen and (min-width: 657px) {
        width: 112px;
        height: 30px;
        margin-right: 9px;
        margin-left: 20px;
        position: absolute;
        right: 9px;
      }
      span {
        color: #fff;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (min-width: 483px) {
          display: none;
        }
        @media screen and (min-width: 657px) {
          display: block;
        }
      }
      .arrow-icon {
        min-width: 16px;
        width: 16px;

        path {
          fill: white;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:55";