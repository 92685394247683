.masthead-settings-container {
  position: relative;
  margin-left: 16px;
  border-radius: 3px;
  font-family: "Inter";

  &.masthead-settings-dropdown_open {
    background-color: #25254b;
  }

  .masthead-settings-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 3px;

    &:hover {
      background-color: #25254b;
      cursor: pointer;
    }

    svg {
      width: 14px;
      height: 14px;
      g,
      path {
        fill: #ffffff;
      }
    }
  }
  .settings-menu {
    position: absolute;
    right: 0;
    width: 236px;
    padding: 0 18px;
    box-shadow: 0px 3px 5px rgba(7, 7, 32, 0.6);
    background-color: #191937;
    border-radius: 3px;
    top: 38px;

    .menu-item-container {
      &:not(:last-child) {
        border-bottom: 1px solid #25254b;
      }

      .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;

        .menu-item_label {
          color: #cddcfe;
          font-size: 12px;
          font-weight: 500;
        }
        .menu-item_icon {
          display: flex;
          align-items: center;

          svg {
            width: 13px;
            height: 13px;

            g,
            path {
              fill: #cddcfe;
            }
          }
        }

        .menu-item_dropdown {
          background-color: #25254b;
          font-size: 10px;
          font-weight: 500;
          padding: 4px 6px;
          border-radius: 3px;
          width: 100px;
          color: #cddcfe;
          display: flex;
          justify-content: space-between;

          svg {
            width: 8px;
          }
        }

        &:hover {
          cursor: pointer;
          .menu-item_label {
            color: #ffffff;
          }
          .menu-item_icon {
            svg {
              g,
              path {
                fill: #ffffff;
              }
            }
          }
          .menu-item_dropdown {
            background-color: #383864;
          }
        }
      }

      .language-dropdown {
        margin-bottom: 10px;
        height: 230px;
        border-radius: 2px;
        overflow: auto;

        label {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          span {
            color: #cddcfe;
            font-size: 10px;
          }

          &:hover {
            span {
              color: #ffffff;
            }

            input ~ .radio-button {
              background-color: #3273fa;
            }
          }

          .radio-button {
            position: absolute;
            left: 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #25254b;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          input:checked ~ .radio-button {
            background-color: #3273fa;
          }
          input:checked ~ span {
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:29";