.default-select {
  font-size: 14px;

  .default-select__control {
    border-radius: $filter-border-radius;
    background-color: var(--filter-color);
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    width: auto;
    height: 100%;
    min-height: auto;
    box-shadow: none;
    transition: none;

    &:hover {
      background-color: var(--filter-option-selected);
    }

    &.default-select__control--is-focused {
    }

    .default-select__value-container {
      display: flex;
      align-items: center;
      flex: 1;

      .default-select__single-value {
        font-family: Helvetica, sans-serif;
        max-width: auto;
        margin-left: 0;
        margin-right: 0;
        color: var(--font-color-main);
        // font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
      }

      .default-select__input {
        color: var(--font-color-main);
        // font-size: 14px;
        font-weight: 700;
        white-space: nowrap;
      }

      .default-select__placeholder {
        color: $grey-text;
        // font-size: 14px;
        font-weight: bold;
      }
    }

    .default-select__indicator-separator {
      display: none;
    }

    .default-select__indicators {
      display: flex;

      .default-select__indicator {
        display: flex;
        align-items: center;

        svg {
          fill: var(--font-color-main);
        }
      }
      .custom-indicator {
        margin-left: 6px;
        width: 7px;

        path {
          fill: #ffffff;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .default-select__menu {
    z-index: 10;
    width: auto;
    border-radius: $filter-border-radius;
    background-color: transparent;
    overflow: hidden;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);

    .default-select__menu-list {
      z-index: 10;
      font-family: Helvetica, sans-serif;
      overflow-y: auto;
      border-radius: $filter-border-radius;
      padding-top: 0px;
      padding-bottom: 0px;
      background-color: var(--filter-color);

      .default-select__option {
        padding: 8px 12px;
        color: var(--font-color-main);
        font-weight: 400;
        border: none;
        // padding: 6px 12px;
        // height: 32px;
        // text-align: left;
        // color: var(--font-color-main);
        // // font-size:14px;
        // font-weight: 400;
        // border: none;
        // color: #cddcfe;

        &:hover {
          cursor: pointer;
          font-weight: 700;
        }

        &.default-select__option--is-focused {
          background-color: var(--filter-option-selected);
          cursor: pointer;
          font-weight: 700;
        }

        &.default-select__option--is-selected {
          background-color: var(--filter-option-selected);
          cursor: pointer;
          font-weight: 700;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:24";